<template>
  <div
    ref="reviews-like"
    v-ada="{level: 1, pos: 0}"
    v-bind="moreAttrs"
    class="review-item__action"
    :class="{'review-item__action-outer': position === 'outer' && !showMore}"
    @click.stop
  >
    <div class="container-left">
      <!-- point program -->
      <template v-if="showPointProgram && !isOutStoreReview">
        <CommentPointProgram />
      </template>

      <!-- sameFlag -->
      <template v-if="showSameFlag && comment.same_flag">
        <span>
          <div
            v-expose="{ id: '2-23-7' }"
            class="review-item__action-program split-left"
          >
            {{ language.SHEIN_KEY_PWA_24441 }}
          </div>
        </span>
      </template>
      <!-- free tips -->
      <template v-if="comment.reportId && isChangeStyle">
        <span>
          <div
            class="review-item__action-program split-left"
          >
            {{ language.SHEIN_KEY_PWA_15207 }}
          </div>
        </span>
      </template>
      <!-- Verified Purchase -->
      <template v-if="isShowVerified">
        <span>
          <div
            class="review-item__action-program split-left"
          >
            {{ language.SHEIN_KEY_PWA_37552 || 'Verified Purchase' }}
          </div>
        </span>
      </template>
    </div>
    <div class="container-right">
      <!-- Like -->
      <template v-if="showLike && !isOutStoreReview">
        <div
          v-if="showPop"
          class="review-item__action-pop"
          aria-hidden="true"
        >
          {{ language.SHEIN_KEY_PWA_17999 }}.
          <i
            class="iconfont icon-close"
            @click="closePop"
          ></i>
        </div>
        <span
          v-if="showPop"
          aria-hidden="true"
          class="review-item__action-icon"
        ></span>
        <button
          class="like-btn"
          aria-hidden="true"
          @click="likeClick"
        >
          <i
            class="suiiconfont"
            :class="{'sui_icon_like_20px': !isLike, 'sui_icon_like_completed_20px': isLike}"
          ></i>
        </button>

        <span
          aria-hidden="true"
          class="help"
        >{{ language.SHEIN_KEY_PWA_16478 }}</span>
        <span
          aria-hidden="true"
          class="like-num"
        >({{ +comment.like_num >= 10000 ? '9999+' : comment.like_num }})</span>
      </template>
      <!-- Report -->
      <template v-if="showReport">
        <CommonReport
          v-bind="reportParams"
          @reportClick="reportClickHandle"
          @reportSubmit="reportSubmitHandle"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { expose } from 'public/src/pages/common/analysis/directive'
import { ada } from 'public/src/pages/common/ada/index.js'
import {
  getLocalStorage,
  setLocalStorage,
  getCookie,
  removeCookie,
  debounce,
} from '@shein/common-function'
import CommonReport from './CommonReport/index'
import CommentPointProgram from './CommentPointProgram'
export default defineComponent({
  name: 'CommentAction',
  directives: {
    expose,
    ada,
  },
  inject: ['COMMENT_INSTANCE'],
  components: { CommonReport, CommentPointProgram },
  data() {
    return {
      showPop: false,
      showTips: false,
      timer: null
    }
  },
  computed: {
    comment() {
      return this.COMMENT_INSTANCE.comment
    },
    language() {
      return this.COMMENT_INSTANCE.language
    },
    isShowVerified() {
      // 仅针对 主商品自己的评论做展示，不包括 试用报告，同款评论，站外评论
      const Verified_Purchase = this.COMMENT_INSTANCE?.reviewUserInfoAbt?.Verified_Purchase
      const isOutStoreReview = this.COMMENT_INSTANCE.isOutStoreReview
      const isSameFlag = this.COMMENT_INSTANCE.showSameFlag && this.comment.same_flag
      const isFree = this.comment.reportId
      return Verified_Purchase && !isOutStoreReview && !isSameFlag && !isFree
    },
    // 头像 / 国家 任意一个abt命中, 星星、日期、试用报告标签的位置变更
    isChangeStyle() {
      const isShowAvatar = this.COMMENT_INSTANCE.reviewUserInfoAbt?.User_Avatar
      const isShowCountry = this.comment.userContr
      return isShowCountry || isShowAvatar
    },
    position() {
      return this.COMMENT_INSTANCE.position
    },
    showMore() {
      return this.COMMENT_INSTANCE.showMore
    },
    index() {
      return this.COMMENT_INSTANCE.index
    },
    isLike() {
      return this.comment.current_member_zan == 1
    },
    showLike() {
      return this.COMMENT_INSTANCE.showLike
    },
    showReport() {
      return this.COMMENT_INSTANCE.showReport
    },
    showPointProgram() {
      return this.COMMENT_INSTANCE.showPointProgram
    },
    showSameFlag() {
      return this.COMMENT_INSTANCE.showSameFlag
    },
    skc() {
      return this.COMMENT_INSTANCE.skc
    },
    analysis() {
      return this.COMMENT_INSTANCE.analysis
    },
    isOutStoreReview() {
      return this.COMMENT_INSTANCE.isOutStoreReview
    },
    // TIPS: 因为详情页的评论 试用数据渗透范围太广了（rp） 这里在组件里判断一下是否试用 仅此一处
    reportParams() {
      const commentId = this.comment.comment_id + ''
      const isFree = !!~commentId.indexOf('rp')
      return {
        iconColor: '#000',
        commentId: isFree ? commentId.replace('rp', '') : commentId,
        title: this.language.SHEIN_KEY_PWA_20498,
        skc: this.skc,
        isFree,
        useBffApi: true,
        analysis: this.analysis,
        isOutStoreReview: this.isOutStoreReview,
        reportMemberId: this.comment?.member_id
      }
    },
    // 盲人友好提示
    moreAttrs() {
      const { showLike, isLike, language } = this
      return {
        'aria-label':
          showLike &&
          (isLike
            ? language.SHEIN_KEY_PWA_17608
            : language.SHEIN_KEY_PWA_16028),
        role: showLike && language.SHEIN_KEY_PWA_17952
      }
    }
  },
  mounted() {
    this.initLikeTips()
  },
  methods: {
    initLikeTips() {
      if (this.index !== 0 || this.position !== 'inner') return

      
      // eslint-disable-next-line @shein-aidc/common/notDirectUseCookie
      if (getCookie('REVIEW_LIKE_TIPS')) { // TODO 代码上线一个月后要下线 2024-03-02
        removeCookie({ key: 'REVIEW_LIKE_TIPS' })
      }

      if (getLocalStorage('REVIEW_LIKE_TIPS')) {
        this.showPop = false
      } else {
        this.scrollToShow()
      }
    },
    scrollToShow() {
      let self = this
      let end = false
      let cb = debounce({
        func: () => {
          const target = self.$refs['reviews-like']
          const viewHeight =
            window.innerHeight || document.documentElement.clientHeight
          if (self.showPop || !target || end) return
          const { top, bottom } = target.getBoundingClientRect()
          if (top >= 0 && bottom <= viewHeight) {
            self.showPop = true
            end = true
            setTimeout(() => {
              self.closePop()
              document
                .querySelector('.j-review-scroll-ctn')
                ?.removeEventListener?.('scroll', cb)
            }, 5000)
          }
        },
        wait: 300
      })
      document
        .querySelector('.j-review-scroll-ctn')
        ?.addEventListener?.('scroll', cb) && cb()
    },
    closePop() {
      this.showPop = false
      setLocalStorage({ key: 'REVIEW_LIKE_TIPS', value: 1 })
    },
    // like callback
    likeClick() {
      const { COMMENT_INSTANCE } = this
      COMMENT_INSTANCE.EVENT_CENTER('likeClick', {
        cb: () => {}
      })
    },
    // report callback
    reportClickHandle(params) {
      const { COMMENT_INSTANCE } = this
      COMMENT_INSTANCE.EVENT_CENTER('reportClick', params)
    },
    // report submit callback
    reportSubmitHandle(params) {
      const { COMMENT_INSTANCE, isOutStoreReview } = this
      COMMENT_INSTANCE.EVENT_CENTER(
        'reportSubmit',
        {
          ...(params || {}),
          isOutStoreReview
        })
    }
  },
})
</script>
<style lang="less">

.review-item{
  &__action {
    width: 100%;
    height: 0.536rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .container-left {
      flex: 1;
      display: flex;
      overflow-x: scroll;
      margin-right: 0.21rem;
      &::-webkit-scrollbar {
        display: none;
      }
      .like-btn {
        margin-right: 0;
      }
    }
    .container-right {
      flex-shrink: 0;
    }

  }
}
</style>
