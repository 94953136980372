<template>
  <div
    v-expose="{
      id: '1-6-1-120',
      data: {
        from: 2
      }
    }"
    class="view-review j-push-history-hash"
    :class="review_abt.reviewText!=='None'? 'view-review__reviewText':''"
    data-floor-tab="reviews"
    :state="reviewCount == 0 ? '' : 'comment-all'"
    @keydown.enter.stop.prevent="viewAll"
    @click="viewAll"
  >
    <SHEINerZone
      v-if="['MiddlePic','BigPic','SmallPic'].includes(review_abt.galleyLayout) || haveTopSHEiner"
      mustShowTitle
    />

    <!-- 有一般评论 or 试用报告时的标题 -->
    <div
      v-if="showInnerReview || (this.goods_detail_store_review_info.showCondition_1 && store_product_comments.length == 0)" 
      class="view-review__title"
      :class="{'zero-reviews': comment_sum_show == 0}"
      :aria-label="language.SHEIN_KEY_PWA_15008"
      tabindex="1"
      :role="language.SHEIN_KEY_PWA_15202"
    >
      <div class="view-review__title-left">
        <span>{{ productReviewTitle }}</span>
        <span>({{ comment_sum_show }})</span>
      </div>
      <div
        v-if="showInnerReview"
        v-expose="viewmoewData('1-6-2-72','scorecard')"
        v-tap="viewmoewData('1-6-2-73','scorecard')"
        class="view-all-btn"
        :class="viewAllBtnClassName"
      >
        <span 
          class="view-all-text"
          :class="{'rating-style': showRatingDetailsAbt.showRatingDetailPage, 'rating-style-strong': showRatingDetailsAbt.showRatingDetailDisplay}"
        >
          {{ viewAllText }}
        </span>
        <i class="suiiconfont sui_icon_more_right_12px_2"></i>
      </div>
    </div>
    <!-- 仅有站外评论时的提示 -->
    <div
      v-else
      class="view-review__only-store-tip"
    >
      <h3>{{ language.SHEIN_KEY_PWA_24443 }}</h3>
      <h2 class="view-review__only-store-tipContent">
        <span>{{ language.SHEIN_KEY_PWA_24442 }}</span>
        <i
          class="iconfont icon-_px product-intro__question"
          @click.stop="storeCommentTipShow = true"
        ></i>
      </h2>
      <SDialog
        v-model:visible="storeCommentTipShow"
        :append-to-body="true"
      >
        <div class="view-review__only-store-tipDialog">
          {{ language.SHEIN_KEY_PWA_24444 }}
        </div>
        <template #footer>
          <div class="view-review__only-store-tipDialogBtnBox">
            <s-button
              :type="['primary', 'H72PX']"
              @click.stop="storeCommentTipShow = false"
            >
              {{ language.SHEIN_KEY_PWA_15146 }}
            </s-button>
          </div>
        </template>
      </SDialog>
    </div>
    <!-- 评论真实性说明文案 -->
    <div
      v-if="isShowValuableReviews"
      v-expose="{id: '1-6-2-87'}"
      class="view-review__valuable-reviews"
    >
      <Icon
        name="sui_icon_certification_16px"
        size="16px"
        color="#198055"
      />
      <span class="view-review__valuable-tips">{{ language.SHEIN_KEY_PWA_31069 || 'SHEIN encourages genuine and valuable reviews.' }}</span>
    </div>
    <RatePanel
      v-if="show_rate_panel && !only_show_same_item"
      class="goods-review__rate-detail"
      :has-true-to-size="has_comments_to_size"
      :is-detail="true"
      :local-review-bar-class-name="localReviewBarClassName"
      @getIsShowRank="getIsShowRank"
    />
    <ReviewLabel
      v-if="comment_tags.length && !only_show_same_item"
      style-type="main"
      reviewTagLine="one"
      :tag-list="comment_tags"
      :free-trail-info-total="fs_comments.reportTotal"
      :show-store-review="goods_detail_store_review_info.showCondition_1"
      @reviewTagIdChange="handleReviewTagClick"
    />
    <SHEINerZone v-if="['MiddlePic_WithReview','SmallPic_WithReview'].includes(review_abt.galleyLayout) && !haveTopSHEiner" />
    <div
      v-if="firstReview.comment_id"
      class="j-expose__reviews__list"
      :data-expose-id="getExposeId(firstReview.comment_id)"
    >
      <div class="j-expose__reviews__item">
        <div
          class="j-expose__reviews__item-con"
          :data-comment-id="firstReview.comment_id"
          :data-comment-page="1"
          :data-index="1"
          :data-out-store-review="firstReview.isOutStoreReview ? '1' : ''"
          data-review-location="page_goods_detail"
          :data-review-rank="isShowRank"
          :data-same-flag="firstReview.same_flag"
        >
          <div
            class="review__contaienr"
            :class="{'reivew-slide__container': isSlideStyle}"
          >
            <div
              v-for="(comment, index) in commentList"
              :key="comment.id"
              v-expose="getAnalysisData({
                ...comment,
                index: Number(index) + 1,
              })"
              v-tap="{
                id: '1-6-2-71',
                data: {
                  review_count: index + 1
                }
              }"
              class="review__item"
            >
              <Comment
                :review-goods-info-abt="review_goods_info_abt"
                :reviewPhotoType="reviewPhotoType"
                :reviewText="review_abt.reviewText"
                :watermark-config="comment_watermark"
                :has-fit="has_fit"
                :language="language"
                :comment-data="comment"
                :show-bra-size="isShowBraSize"
                :translate-language="translate_language"
                :translate-loading="translateloaing"
                :is-batch-translate="is_batch_translate"
                :is-auto-translate="is_auto_translate"
                :curr-translate-lang="curr_translate_lang"
                :hide-more-text="true"
                :skc="goods_sn"
                :cat-id="cat_id"
                :show-report="true"
                :show-translate="showTranslate"
                :show-member-size="!isSlideStyle"
                :is-slide-style="isSlideStyle"
                :show-point-program="show_point_program && !isSlideStyle"
                :analysis="{ activity_from: 'page_goods_detail', location: 1 }"
                :comment-date-display-new="comment_date_display_new"
                :reviewDetaillayoutAbt="review_detail_layout_abt"
                :reviewUserInfoAbt="review_user_info_abt"
                @sizeShowAll="sizeShowAllHandle"
                @contentShowAll="contentShowAllHandle($event, 'outer')"
                @showOriginComment="showOriginComment($event, 'outer')"
                @closeTranslate="closeTranslateHandle($event, 'outer')"
                @imageClick="imageClickHandle($event, 'outer')"
                @clickTranslate="clickTranslateHandle($event, 'outer')"
                @likeClick="likeClickHandle($event, comment, 'outer')"
                @changeTranslateLanguage="changeTranslateLanguage($event, 'outer')"
                @addCart="addCart($event, 'outer')"
              />
            </div>
            <div
              v-if="isSlideStyle && reviewCount > showCount"
              class="review__more j-sa-good-detial-review-all"
            >
              <div
                v-expose="viewmoewData('1-6-2-72','slicecard')"
                v-tap="viewmoewData('1-6-2-73','slicecard')"
                class="review__more-box"
              >
                <i class="iconfont icon-liebiaoviewmore icon"></i>
                <span>{{ language.SHEIN_KEY_PWA_15040 }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="reviewCount > showCount && review_abt.reviewText !== 'SimpleVersion2'"
      v-expose="viewmoewData('1-6-2-72','bottom')"
      v-tap="viewmoewData('1-6-2-73','bottom')"
      class="view-review__all view-review__all-hook waves-effect j-sa-good-detial-review-all"
      :class="{ 'show-store-review': goods_detail_store_review_info.show}"
      tabindex="1"
      :aria-label="language.SHEIN_KEY_PWA_17256"
      :role="language.SHEIN_KEY_PWA_17952"
    >
      {{ language.SHEIN_KEY_PWA_15040 }}
      <i
        class="view-review__all-hook suiiconfont sui_icon_more_right2_16px"
      ></i>
    </div>
    <SHEINerZone 
      v-if="['MiddlePic_MoveBack','SmallPic_MoveBack'].includes(review_abt.galleyLayout)" 
      style="border-top: 1px solid #e5e5e5;"  
    />
    <StoreReview
      source-page="page_goods_detail"
      :store-review-info="goods_detail_store_review_info"
      v-if="goods_detail_store_review_info.show"
    />
  </div>
</template>
<script>
import { defineComponent, nextTick } from 'vue'
import { expose, tap } from 'public/src/pages/common/analysis/directive'
import Comment from './components/Comment'
import RatePanel from './components/RatePanel'
import ReviewLabel from './components/ReviewLabel'
import StoreReview from './components/StoreReview'
import commentMixin from './mixin/comment.js'
import SHEINerZone from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/CustomerGallery/SHEINerZone.vue'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import GBDetailAnalysisEvent from 'public/src/pages/goods_detail_v2/analysis/ga.js'
import { SHOW_CAT_ID } from './constants'
import { Icon } from '@shein-aidc/icon-vue3'

const { langPath, SiteUID } = gbCommonInfo
daEventCenter.addSubscriber({ modulecode: '1-6-1' })

export default defineComponent({
  name: 'ReviewsPanel',
  directives: {
    expose,
    tap,
  },
  components: {
    Icon,
    ReviewLabel,
    StoreReview,
    Comment,
    RatePanel,
    SDialog,
    SHEINerZone,
    SButton,
  },
  mixins: [commentMixin],
  data() {
    return {
      SiteUID,
      isBack: false,
      // 查看全部评论按钮类名
      viewAllBtnClassName: 'view-all-handler',
      // 本地评论栏的类名
      // * 因点击事件代理到本组件，评论栏需要用一个单独的类名在点击事件中识别
      localReviewBarClassName: 'local-review-box-handler',
      // 本地评论栏组件dom深度
      // * 在点击事件中用以向上检索父级是否匹配本地评论栏类名，判断target是否在本地评论栏中
      localReviewBarDeep: 3,
      // 仅有站外评论提示框展示状态
      storeCommentTipShow: false,
      isShowRank: 0
    }
  },
  provide() {
    return {
      RELATIVE_SPU: this.product_relation_id || '',
      PAGE_FROM: 'goods_detail'
    }
  },
  computed: {
    // new bff vuex
    ...mapState('productDetail', ['MAIN_BFF_APOLLO']),
    ...mapGetters('productDetail/common', [
      'fs_abt',
      'has_fit',
      'product_relation_id',
      'language_v2',
      'goods_sn',
      'cat_id',
    ]),
    ...mapGetters('productDetail/Reviews', [
      'detail_reviews',
      'fs_comments',
      'comment_sum_show',
      'comment_tags',
      'translate_language',
      'has_comments_to_size',
      'show_rate_panel',
      'show_point_program',
      'comment_date_display_new',
      'is_batch_translate',
      'showRatingDetailsAbt',
      'comment_watermark',
      'goods_detail_store_review_info',
      'review_goods_info_abt',
      'review_detail_layout_abt',
      'review_user_info_abt',
      'store_product_comments'
    ]),
    ...mapGetters('productDetail/CustomerGallery', [
      'review_abt',
    ]),
    //
    reviewPhotoType() {
      return this.fs_abt?.Reviewchange?.param?.Reviewphoto || 'None' 
    },
    isShowValuableReviews() {
      // 站外评论不显示
      return this.fs_abt?.Reviewchange?.param?.Comment_des === 'Yes' && this.showInnerReview
    },
    ...mapGetters('productDetail/CommentPopup', [
      'language_config_set',
      'only_show_same_item'
    ]),
    ...mapState('productDetail/CommentPopup', [
      'view_comment',
      'translateloaing',
      'curr_translate_lang',
      'is_auto_translate',
      'onlyLocalStatus'
    ]),

    language() {
      return this.language_v2
    },
    firstReview() {
      return this.detail_reviews[0] || {}
    },
    productReviewTitle(){
      const reviewText = this.goods_detail_store_review_info.showCondition_1 ? (this.language.SHEIN_KEY_PWA_35092 || 'Product Reviews') : this.language.SHEIN_KEY_PWA_15008
      return this.only_show_same_item ? this.language.SHEIN_KEY_PWA_31288 : reviewText
    },
    viewAllText() {
      if(this.showRatingDetailsAbt.showRatingDetailPage) return this.showRatingDetailsAbt.ratingDetailText
      return this.only_show_same_item ? '' : this.language.SHEIN_KEY_PWA_22253
    },
    isShowBraSize() {
      const { cat_id } = this
      return cat_id && SHOW_CAT_ID.includes(Number(cat_id))
    },
    // Show_count_1 外露1条
    // Show_count_2 外露2条
    // Show_count_3 外露3条 (默认)
    // Show_count_3_slidecard 外露3条,横滑卡片交互
    reviewStyleAbt() {
      return this.fs_abt?.Reviewchange?.param?.Review_show_count || 'Show_count_3'
    },
    // 限制只有走到了小icon样式的用户，再进一步判断以下4个分支
    showCount() {
      if (this.reviewPhotoType !== 'Smallicon' || this.reviewCount < 2) return 1 // 防止一条评论出现卡片式

      const map = {
        Show_count_1: 1,
        Show_count_2: 2,
        Show_count_3: 3,
        Show_count_4: 4,
        Show_count_3_slidecard: 3,
      }
      return map[this.reviewStyleAbt] || 1
    },

    haveTopSHEiner() {
      return (['MiddlePic_WithReview', 'SmallPic_WithReview'].includes(this.review_abt.galleyLayout) && this.only_show_same_item)
    },
    showTranslate() {
      return !this.isSlideStyle && !['SimpleVersion1', 'SimpleVersion2'].includes(this.review_abt.reviewText)
    },
    isSlideStyle() {
      // 限制只有走到了小icon样式的用户，再进一步判断以下4个分支
      if (this.reviewPhotoType !== 'Smallicon') return false
      return this.reviewStyleAbt === 'Show_count_3_slidecard' && this.reviewCount > 1 // 起码2条才用卡片式
    },
    commentList() {
      const list = this.detail_reviews.slice(0, this.showCount)
      // 需要过滤，标记出第一次出现的商品ID并且有commentHotOrSoldOutLabel的评论
      if(this.review_goods_info_abt.display_limit) {
        const goodsIdObj = {}
        list.forEach(item => {
          if(!item.goods_id || (!goodsIdObj[item.goods_id] && item.commentHotOrSoldOutLabel)) {
            item.isFirstGoodsId = true
            item.goods_id && (goodsIdObj[item.goods_id] = true)
          }
        })
      }
      console.log('list', list)
      return list
    },
    // 商详页评论评论总数（最多4条的）
    reviewCount() {
      return this.detail_reviews.length
    },
    // 是否有普通评论or试用报告 (站外评论不算)
    showInnerReview(){
      return parseInt(this.comment_sum_show) > 0
    }
  },
  watch: {
    view_comment() {
      nextTick(() => {
        if (!this.isBack) return
        this.isBack = false
      })
    },
    product_relation_id() {
      nextTick(() => {
        this.update_only_local_status(false)

        // 检测如果是批量翻译的话 自动更新批量翻译相关内容
        if (this.is_batch_translate) {
          this.fetch_batch_translate_firstComment()
        }
      })
    }
  },
  mounted() {
    this.exposeDetailReviews = GBDetailAnalysisEvent.exposeDetailReviews()

    this.initConfigData() // 初始化语言配置

    // 检测如果是批量翻译的话 自动更新批量翻译相关内容
    if (this.is_batch_translate) {
      this.fetch_batch_translate_firstComment()
    }
  },
  unmounted() {
    this.exposeDetailReviews?.destroyInstance()
  },
  methods: {
    ...mapMutations('productDetail/CommentPopup', [
      'update_need_scroll_review_filter',
      'update_comment_filter_data',
      'open_view_comment_pop',
      'update_only_local_status',
      'update_local_top',
      'update_default_lang'
    ]),
    ...mapActions('productDetail/Reviews', ['fetch_batch_translate_firstComment']),
    overFitValue(comment) {
      return (
        this.has_fit &&
        (+comment?.member_cat_size?.member_overall_fit ||
          +comment?.member_size?.member_overall_fit)
      )
    },
    miniMemberSize(comment) {

      let miniMemberSize = []
      let arrLength = this.overFitValue(comment) ? 1 : 2
      Object.keys(comment.member_size).forEach(sizeKey => {
        if (
          (sizeKey != 'member_overall_fit' &&
            sizeKey != 'member_brasize' &&
            sizeKey != 'member_bra_size' &&
            comment.member_size[sizeKey]) ||
          ((sizeKey == 'member_brasize' || sizeKey == 'member_bra_size') &&
            this.showBraSize &&
            comment.member_size[sizeKey])
        ) {
          if (miniMemberSize.length < arrLength) {
            miniMemberSize.push({
              sizeKey: sizeKey,
              sizeValue: comment.member_size[sizeKey]
            })
          }
        }
      })
      return miniMemberSize
    },
    miniSizeInfo(comment) {
      let miniSizeInfo = []
      let arrLength = this.overFitValue(comment) ? 1 : 2
      if (comment.member_cat_size?.size_info?.length) {
        for (let i = 0; i < comment.member_cat_size.size_info.length; i++) {
          if (comment.member_cat_size.size_info[i]) {
            miniSizeInfo.push(comment.member_cat_size.size_info[i])
            if (miniSizeInfo.length === arrLength) return miniSizeInfo
          }
        }
        return miniSizeInfo
      } else {
        return []
      }
    },
    getAnalysisData({ 
      comment_id = '', 
      index, 
      isOutStoreReview, 
      sameFlag, 
      comment_image, 
      content_tag, 
      member_size_new = [],
      tipsTagHotOrSoldOut = [],
      userContr = ''
    }) {
      const { translate_language, show_point_program, review_abt } = this
      const commentIdStr = String(comment_id || '')
      const isReport = commentIdStr.indexOf('rp') > -1
      const reviewTypeStr = isOutStoreReview ? 1 : isReport ? 2 : 0
      const hiddenReviewPart = review_abt.reviewText !== 'None'
      const contentTag = (() => {
        if (!Array.isArray(content_tag)) {
          return []
        }
        return content_tag.filter(tag => tag && tag.name && tag.content)
      })()

      const isSizeInfoExpand = (() => {
        if (hiddenReviewPart && isOutStoreReview) return 0
        const filterArr = member_size_new?.filter?.(item => item.rule_name_en !== 'member_overall_fit') || []
        return +(filterArr?.length > 1)
      })()
      const trueToSizeItem = member_size_new?.find?.(item => item.rule_name_en === 'member_overall_fit') || {}
      const is_truetosize = isReport ? trueToSizeItem?.member_overall_fit_type || '' : trueToSizeItem?.member_overall_fit || ''
      const is_almostsoldout = !!tipsTagHotOrSoldOut?.find?.(item => item.commentHotOrSoldOutLabelType === '1')
      const is_hotsell = !!tipsTagHotOrSoldOut?.find?.(item => item.commentHotOrSoldOutLabelType === '2')
      return {
        id: '1-6-7-5',
        data: {
          review_id: comment_id, // 评论 ID
          position: index, // 位置序号，从 1 递增
          page: 1, // 页码序号，从 1 递增
          review_type: reviewTypeStr, //评论类型，0=站内评论，1=站外评论，2=试用报告
          review_source: sameFlag || 0, //评论来源，0=主商品评论，1=同款评论（包括保留款 + buybox最低价款）
          is_translate: translate_language?.length && !hiddenReviewPart > 0 ? 1 : 0, // 是否有单条翻译，0=无翻译按钮展示，1=有翻译按钮展示
          is_point_rating: +show_point_program, // 是否有 Point Program，0=无Point Program展示，1=有Point Program展示
          image_num: comment_image?.length ?? 0, //评论中图片数量，0=评论无图，有图的情况下有几张图片报几张图片
          is_size_info_expand: isSizeInfoExpand, //是否有尺码信息展开，0=没有尺码信息展开，1=有尺码信息展开
          is_guide_label: contentTag.length ? 1 : 0, //是否有 引导标签，0=没有引导标签，1=有引导标签
          is_almostsoldout: +is_almostsoldout, //是否有低库存，1=有，0=无
          is_hotsell: +(is_hotsell && !is_almostsoldout), //是否有是否有热销，1=有，0=无 有低库存热销要报0
          is_addtocart: +!!this.review_goods_info_abt.is_show_add_cart, //是否有加车，1=有，0=无
          is_truetosize: is_truetosize,
          is_nation: userContr ? 1 : 0,
        }
      }
    },
    viewmoewData(id, postion) {
      const data = {
        viewmore_location: postion
      }
      if(id === '1-6-2-72') {
        data.is_review_detail = postion === 'scorecard' && !!this.showRatingDetailsAbt.fiveStarRating ? 1 : 0
        data.review_detail = postion === 'scorecard' ? this.showRatingDetailsAbt.fiveStarRating : ''
      }
      return {
        id, 
        data
      }
    },
    goToGuidance() {
      window.location.href =  `${langPath}/Review-Guidance-a-478.html?lang=eur`
    },
    initConfigData() {
      const languageConfigSet = this.language_config_set || []
      const defaultLang =
        localStorage.getItem('defaultLang') ||
        (languageConfigSet.includes(navigator.language) && navigator.language) ||
        (languageConfigSet.includes(gbCommonInfo.lang) && gbCommonInfo.lang) ||
        languageConfigSet[0]
      this.update_default_lang(defaultLang)
    },
    getIsShowRank(res) {
      this.isShowRank = res ? 1 : ''
    },
    getExposeId(id) {
      return `detail_review${id}_${getSaPageInfo.tab_page_id}`
    },
    handleReviewTagClick(id) {
      this.update_comment_filter_data({
        sort: '',
        tag_id: id,
        cur_page: 1,
        onlyShowTrialReport: false,
        commentFilterAttrSizeId: '',
        // commentFilterColorId: '',
        commentFilterGoodsId: '',
        currSizeIndex: -1,
        withPicture: '',
        comment_rank: ''
      })
      this.changeHash('comment-all')
      this.open_view_comment_pop({ from: 'tag' })
    },
    changeHash(value) {
      location.hash = value ? '#' + value : ''
    },
    // 检测dom是否在本地评论栏中
    hitLocalReviewBar(target) {
      if (!(target instanceof HTMLElement)) {
        return false
      }
      const { localReviewBarClassName, localReviewBarDeep } = this
      if (target.className.indexOf(localReviewBarClassName) > -1) {
        return true
      }

      let currentTarget = target
      for (let i = 0; i < localReviewBarDeep; i++) {
        currentTarget = currentTarget.parentElement
        if (!currentTarget || currentTarget === document.body) {
          return false
        }
        if (currentTarget.className.indexOf(localReviewBarClassName) > -1) {
          return true
        }
      }

      return false
    },
    viewAll({ target = {} }) {
      if(this.reviewCount == 0){
        return
      }
      let viewLocal = false // 是否自动打开本地评论开关
      if (target?.className?.indexOf?.(this.viewAllBtnClassName) === -1) {
        viewLocal = this.hitLocalReviewBar(target)
      } // 如果是点击本地评论的进去 要自动打开本地评论开关 并置顶
      this.isBack = true
      this.update_only_local_status(viewLocal)
      this.update_local_top(viewLocal)
      this.open_view_comment_pop()
      this.viewAllEvent(target)

      if(this.fs_abt?.ReviewDetaillayout?.param?.Reviewcard === 'New'){
        this.update_need_scroll_review_filter(true)
      }
    },
    viewAllEvent(target) {
      const isHot = !~target?.className?.indexOf('view-review__all-hook') // 是否热区点击
      if (!isHot) {
        daEventCenter.triggerNotice({
          daId: '1-6-7-50'
        })
      }
      daEventCenter.triggerNotice({
        daId: '1-6-1-121',
        extraData: {
          from: 2
        }
      })
    },
    sizeShowAllHandle() {
      daEventCenter.triggerNotice({
        daId: '1-6-7-33'
      })
    }
  },
})
</script>

<style lang="less">
.view-review {
  background: #fff;
  position: relative;

  &__title {
    padding-top: 0.2667rem;
    // padding-bottom: 0.05rem;
    margin: 0 0.32rem;
    .flexbox;
    justify-content: space-between;
    line-height: 0.51rem;
    .font-dpr(28px);
    font-weight: bold;
    /* rw:begin */
    font-family: 'Adieu';
    &.zero-reviews{
      padding: 0.32rem 0
    }

    .view-all-btn {
      display: flex;
      align-items: center;
      .font-dpr(24px);
      font-weight: normal;
      color: @sui_color_gray_dark2;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-left: 0.10667rem;
    }
    .view-all-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &.rating-style {
        font-weight: 590;
      }
      &.rating-style-strong {
        color: #EAAB00;
      }
    }
  }
  &__title-left {
    .flexbox;
    .align-center;
    gap:4px;
    white-space: nowrap;
  }
  &__valuable-reviews{
    margin: 0.21rem 0.32rem 0;
    padding: 0.16rem 0.21333rem;
    background: #F5FCFB;
    display: flex;
    align-items: flex-start;
  }
  &__valuable-tips{
    .font-dpr(22px);
    color: #198055;
    margin-left: 0.10667rem;
  }
  .view-review-null {
    .page-inner {
      padding: 2rem 0;
      text-align: center;
      > i {
        font-size: 50px;
        margin-bottom: 0.5rem;
      }
      > p {
        .font-dpr(32px);
        color: #666;
      }
    }
  }
  &__sort-list {
    background: #fff;
    width: 10rem;
    margin: 0 auto;
    padding: 0 0.32rem;
    box-sizing: border-box;
    overflow-y: auto;
    // height: 4.2664rem;
    // height: 3.18rem;
    > li {
      .flexbox();
      .space-between();
      .align-center();
      height: 1.06rem;
      line-height: 1.06rem;
      border-top: 1px solid #e5e5e5;
    }
  }

  &__report-tab {
    height: 1.28rem;
    .flexbox();
    .space-between();
    .align-center();
    padding: 0 0.32rem;
    font-weight: bold;
    position: relative;
    &.open {
      .border-dpr(border-bottom, 2px, transparent);
    }
    &.noborder {
      border: none;
    }
    .tabname {
      font-size: 0.374rem;
    }
    span {
      color: #999;
    }
  }
  .view-review__report-tab{
    &:not(:first-child){
      .border-dpr(border-top, 2px, #e5e5e5);
    }
  }
  &__all {
    width: 100%;
    text-align: center;
    height: 0.96rem;
    line-height: 0.96rem;
    border-top: 1px solid #f6f6f6;
    background: #fff;
    color: #999;
    /* rw:begin */
    .font-dpr(28px);
    color: @sui_color_gray_dark3;
    [class*='iconfont'] {
      display: inline-block;
      .margin-l(0.026667rem);
      .font-dpr(28px);
      color: #999;
      vertical-align: top;
      /* rw:begin */
      color: @sui_color_gray_dark3;
    }
    &.show-store-review{
      border-top: none;
      height: 0.426rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: -0.213rem 0 0.32rem 0;
    }
  }
}

.review{
  &__item {
    border-bottom: 1px solid #f6f6f6;
    &:last-child{
      border: none;
    }
  }
}
/*
Show_count_3_slidecard： 横滑卡片交互
*/
.reivew-slide__container{
  display: flex;
  overflow: scroll;
  margin-top: 0.37rem;
  padding-bottom: 0.37rem;
  /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
  -ms-overflow-style: none;
  /*火狐下隐藏滚动条*/
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  /*Chrome下隐藏滚动条，溢出可以透明滚动*/
  &::-webkit-scrollbar {
    display: none;
  }
  .review {
    &__item{
      width: 7.62667rem;
      flex-shrink: 0;
      min-height: 4rem;
      border-radius: 0.21333rem;
      border: 1px solid #F0F0F0;
      margin-right: 0.21rem;
      &:first-child{
        margin-left: 0.32rem;
      }
    }
    &__more {
      min-height: 4rem;
      flex-shrink: 0;
      width: 2.02667rem;
      border-radius: 0.21333rem;
      margin-right: 0.32rem;
      border: 1px solid #F0F0F0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &__more-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .icon {
        color: #222222;
        font-size: 40px;
      }
      color: #000;
      font-family: "SF Pro";
      font-size: 0.32rem;
      font-style: normal;
      font-weight: 400;
    }
  }
}
.view-review__only-store-tip {
  width: 100%;
  padding: 0.32rem 0.32rem 0;

  h3 {
    .font-dpr(28px);
    font-weight: 400;
    line-height: 0.64rem;
    color: @sui_color_gray_dark2;
  }

  .view-review__only-store-tipContent {
    margin-top: 0.32rem;
    .flexbox();

    span,
    i {
      display: inline-block;
    }
    span {
      .font-dpr(24px);
      font-weight: 600;
    }
    i {
      padding: 0 0.16rem;
      color: #959595;
    }
  }
}

.view-review__only-store-tipDialog {
  color: @sui_color_gray_dark1;
  .font-dpr(28px);
  font-weight: 400;
  margin: 0.213333rem 0;
  text-align: center;
}
.view-review__only-store-tipDialogBtnBox {
  padding: 0.533333rem 0;

  button {
    width: 100%;
    color: #fff;
    .font-dpr(28px);
    font-weight: 700;
  }
}
.goods-review__rate-detail{
  margin: 0.16rem 0.32rem 0.375rem 0.32rem;
}
</style>
