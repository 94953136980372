<template>
  <s-popover
    v-model="showTips"
    class="point-program__pop"
    :placemen="placemen"
    theme="light"
    trigger="user"
    :append-to-body="false"
    :prop-style="pointProgramStyle"
    :content="language.SHEIN_KEY_PWA_21721"
  >
    <template #reference>
      <div
        ref="PROGRAM_REF"
        class="review-item__action-program"
        @click="clickPointHandle"
      >
        {{ language.SHEIN_KEY_PWA_21720 }}
      </div>
    </template>
  </s-popover>
</template>

<script>
import { defineComponent } from 'vue'
import { SPopover } from '@shein-aidc/sui-popover/mobile'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { throttle } from '@shein/common-function'
export default defineComponent({
  name: 'CommentPointProgram',
  components: {
    SPopover,
  },
  inject: ['COMMENT_INSTANCE'],
  data() {
    return {
      showTips: false,
      placemen: 'bottom-start'
    }
  },
  computed: {
    pointProgramStyle() {
      return { width: '60vw' }
    },
    language() {
      return this.COMMENT_INSTANCE.language
    },
    scrollContainer() {
      const select = this.COMMENT_INSTANCE.scrollContainer
      if (!select) return window
      return document.querySelector(select) || window
    }
  },
  mounted() {
    this.scrollContainer.addEventListener('scroll', this.bindScrollEvent)
  },
  unmounted() {
    this.scrollContainer.removeEventListener('scroll', this.bindScrollEvent)
  },
  methods: {
    clickPointHandle() {
      daEventCenter.triggerNotice({
        daId: '2-23-4'
      })

      const screenHeight = window.innerHeight || document.documentElement.clientHeight
      const { bottom } = this.$refs['PROGRAM_REF'].getBoundingClientRect()

      const { index, totalComment } = this.COMMENT_INSTANCE || {}

      // 当数量小于4（无法撑满一屏）且当前为倒数最后一个项目，pop方向为顶部，防止被父元素遮挡
      const isLowAndLastComment = totalComment > 0 && totalComment < 4 && totalComment - index === 1
      if (screenHeight - bottom <= 165 || isLowAndLastComment) {
        this.placemen = 'top-start'
      } else {
        this.placemen = 'bottom-start'
      }
      this.showTips = true
      
      this.setTimer()
    },
    setTimer(holding) {
      this.clearPointTimeout()
      if (holding) return false
      this.timer = setTimeout(() => {
        this.showTips = false
      }, 3000)
    },
    clearPointTimeout() {
      this.timer && clearTimeout(this.timer)
    },
    bindScrollEvent: throttle({
      func: function() {
        this.clearPointTimeout()
        this.showTips = false
      },
      wait: 50
    })
  },
})
</script>
