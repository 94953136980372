<template>
  <div @click.stop>
    <div
      v-if="!hasCommentContent && translateLanguage.length > 0 && flag"
      class="item-see-translate"
      role
      :aria-label="language.SHEIN_KEY_PWA_17952"
    >
      <button
        v-if="translateLoading.includes(comment.comment_id)"
        tabindex="1"
      >
        {{ language.SHEIN_KEY_PWA_17086 }}
      </button>
      <button
        v-else-if="!isBatchTranslate"
        :data-review-id="comment.comment_id"
        tabindex="1"
        :role="language.SHEIN_KEY_PWA_17083"
        :class="'j-scroll-expose-translate'"
        @click="clickTranslate"
      >
        {{ language.SHEIN_KEY_PWA_17047 }}
      </button>
      <button
        v-else-if="isBatchTranslate && !comment.reportId"
        :data-review-id="comment.comment_id"
        tabindex="1"
        :role="language.SHEIN_KEY_PWA_18794"
        :class="'j-scroll-expose-translate'"
        @click="showOriginComment"
      >
        {{ comment && comment.showOrigin ? language.SHEIN_KEY_PWA_17047 : language.SHEIN_KEY_PWA_18794 }}
      </button>
      <a
        v-if="!(comment &&comment.showOrigin)&&isBatchTranslate&&!comment.reportId"
        href="http://translate.google.com"
        target="_blank"
      >
        <img
          class="item-translate-by"
          :src="`${PUBLIC_CDN}/pwa_dist/images/greyscale-short-d098887b55.png`"
        />
      </a>
    </div>
    <S-slide
      ref="s-slide"
      :class="{
        'item-translate-slide': showDesc
      }"
      v-model:visible="showDesc"
    >
      <div
        v-if="showDesc"
        class="item-translate-wrap"
        tabindex="1"
        :type="language.SHEIN_KEY_PWA_17951"
        :aria-label="comment.translateCon"
      >
        <p class="item-translate-text">
          {{ decodeText }}
        </p>
        <ul
          v-if="commentTags.length"
          class="comment-tag-box"
        >
          <li
            v-for="(tag, tagIndex) in commentTags"
            :key="tagIndex"
          >
            <span class="tag-name">{{ tag.name }}</span>: <span
              class="tag-content"
            >{{ tag.content }}</span>
          </li>
        </ul>
        <i
          class="iconfont icon-close item-translate-close"
          @click="closeCommentTranslate"
        ></i>
        <div class="item-translate-footer">
          <a
            href="http://translate.google.com"
            target="_blank"
          >
            <img
              class="item-translate-by"
              :src="`${PUBLIC_CDN}/pwa_dist/images/greyscale-short-d098887b55.png`"
            />
          </a>
          <span
            class="item-translate-select"
            @click="changeTranslateLanguage"
          >
            {{ displayLanguage }}
            <i
              v-if="translateLanguage.length > 1"
              class="iconfont icon-Rectanglex"
            ></i>
          </span>
        </div>
      </div>
    </S-slide>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
import { SSlide } from '@shein-aidc/sui-slide/mobile'
import { htmlDecode } from '@shein/common-function'
const { PUBLIC_CDN } = gbCommonInfo
export default defineComponent({
  name: 'CommentTranslate',
  components: {
    SSlide,
  },
  inject: ['COMMENT_INSTANCE'],
  data() {
    return {
      flag: true,
      PUBLIC_CDN
    }
  },
  computed: {
    comment() {
      return this.COMMENT_INSTANCE.comment
    },
    // 当前是否存在评论内容，包括评论正文和标签
    hasCommentContent() {
      const { comment } = this
      if (!comment) {
        return false
      }
      const { translateCon, translateConTags } = comment
      if (Array.isArray(translateConTags) && translateConTags.length) {
        return true
      }
      return !!translateCon
    },
    language() {
      return this.COMMENT_INSTANCE.language
    },
    position() {
      return this.COMMENT_INSTANCE.position
    },
    translateLoading() {
      return this.COMMENT_INSTANCE.translateLoading
    },
    isAutoTranslate() {
      return this.COMMENT_INSTANCE.isAutoTranslate
    },
    isBatchTranslate() {
      return this.COMMENT_INSTANCE.isBatchTranslate
    },
    translateLanguage() {
      return this.comment.translate_language
    },
    displayLanguage() {
      return this.translateLanguage.find(lang => lang.translate_language === this.comment.translateLang)?.display_language || ''
    },
    showDesc: {
      get() {
        const { hasCommentContent } = this
        return hasCommentContent
      },
      set(val) {
        return val
      }
    },
    decodeText() {
      return htmlDecode({ text: this.comment.translateCon })
    },
    // 评论引导标签
    commentTags() {
      const { comment } = this
      const { translateConTags } = comment
      if (Array.isArray(translateConTags)) {
        return translateConTags.filter(tag => tag && tag.name && tag.content)
      }
      return []
    },
  },
  watch: {
    isAutoTranslate(val) {
      this.updateComment('showOrigin', !val)
      if (val) this.closeCommentTranslate()
    },
    showDesc() {
      this.updateHeight()
    }
  },
  methods: {
    closeCommentTranslate() {
      const { updateComment, COMMENT_INSTANCE } = this
      updateComment('translateCon', '')
      updateComment('translateConTags', [])
      updateComment('translateConSelectTags', [])
      this.$emit('closeTranslate')
      this.flag = true
      COMMENT_INSTANCE.EVENT_CENTER('closeTranslate', this.comment.reportId)
    },
    /**
     * 点击翻译
     */
    clickTranslate(source) {
      if(source === 'content') { // 从评论内容点击翻译，不需要展示tranlate按钮
        this.flag = false
      }
      const { comment } = this
      const { content, comment_id, language_flag } = comment
      const { COMMENT_INSTANCE } = this
      COMMENT_INSTANCE.EVENT_CENTER('clickTranslate', {
        comment_id,
        comment_lang: language_flag,
        content,
        report_id: this.comment.reportId,
        comment_el: COMMENT_INSTANCE.$el,
        isOutStoreReview: COMMENT_INSTANCE.isOutStoreReview,
      })
    },
    updateComment(key, value) {
      const { COMMENT_INSTANCE } = this
      const data = { ...this.comment, [key]: value }
      COMMENT_INSTANCE.UPDATE_STATE('comment', data)
    },
    /**
     * 修改当前语种
     */
    changeTranslateLanguage() {
      const { comment, COMMENT_INSTANCE, updateHeight } = this
      const { content, comment_id, language_flag, translateLang } = comment
      console.log('changeTranslateLanguage', comment)
      COMMENT_INSTANCE.EVENT_CENTER('changeTranslateLanguage', {
        report_id: this.comment.reportId,
        translate_lang: translateLang,
        language_flag,
        comment_lang: language_flag,
        comment_id,
        content,
        isOutStoreReview: COMMENT_INSTANCE.isOutStoreReview,
        cb: updateHeight
      })
    },
    showOriginComment() {
      const { COMMENT_INSTANCE, updateComment, comment } = this
      updateComment('showOrigin', !comment.showOrigin)
      COMMENT_INSTANCE.EVENT_CENTER('showOriginComment', {
        showOrigin: comment.showOrigin,
        review_id: comment.comment_id
      })
    },
    updateHeight() {
      nextTick(() => {
        this.$refs['s-slide'].calHeight()
      })
    }
  },
})
</script>

<style lang="less" scoped>
  .comment-tag-box {
    .tag-name {
      color: @sui_color_gray_dark2;
    }
  }
</style>
