<template>
  <div
    class="review-item__inout review-item__inner"
    :class="{ 'review-item__inner-flex': reviewPhotoType==='Smallpic' }"
  >
    <div>
      <!-- Content -->
      <CommentContent 
        :position="position" 
        :showReviewPhoto="reviewPhotoType"
        :reviewPhotoType="reviewPhotoType"
        :reviewText="reviewText"
        :is-new-translate-layout="isNewTranslateLayout"
        @translateClick="handleTranslateClick"
      />
      <!-- Translate -->
      <CommentTranslate
        v-if="isShowTranslate"
        ref="commentTranslate"
        @closeTranslate="innerTranslateHide = false"
      />
    </div>
    <!-- Images -->
    <CommentImages
      v-if="!COMMENT_INSTANCE.isOutStoreReview"
      :reviewPhotoType="reviewPhotoType"
    />
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
import CommentTranslate from './components/CommentTranslate'
import CommentContent from './components/CommentContent'
import CommentImages from './components/CommentImages'

export default defineComponent({
  name: 'CommentInner',
  components: { CommentContent, CommentTranslate, CommentImages },
  inject: ['COMMENT_INSTANCE'],
  props: {
    position: {
      type: String,
      default: 'outer'
    },
    reviewPhotoType: {
      type: String,
      default: 'None'
    },
    reviewText: {
      type: String,
      default: 'None'
    }
  },
  data() {
    return {
      innerTranslateHide: false // 主要用于在内容后面的翻译按钮点击后隐藏，然后再显示内容下面的翻译组件
    }
  },
  computed: {
    isShowTranslate() {
      return this.COMMENT_INSTANCE.showTranslate && (!this.isNewTranslateLayout || this.innerTranslateHide)
    },
    isNewTranslateLayout() {
      return this.COMMENT_INSTANCE.reviewDetaillayoutAbt && this.position === 'inner'
    },
  },
  methods: {
    async handleTranslateClick() {
      // 新的翻译按钮，还是调用下面的翻译组件
      this.innerTranslateHide = true
      await nextTick()
      this.$refs.commentTranslate.clickTranslate('content')
    }
  }
})
</script>
<style lang="less">
.review-item {
  &__inner-flex {
    display: flex;
    justify-content: space-between;
    gap: .2133rem;
  }
}
</style>
