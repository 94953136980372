<template>
  <div :class="[position === 'inner' ? 'reviews-item-user' : '']">
    <div class="review-item__info">
      <div class="review-item__info-item">
        <Icon
          v-if="isShowAvatar"
          class="review-item__info-avatar"
          :name="avatarIconName" 
          is-responsive-name
          size="18px"
        />
        <div
          class="review-item__info-name"
          aria-hidden="true"
        >
          {{ userName }}
        </div>
        <!-- 星级 -->
        <div
          v-if="!isChangeStyle"
          v-ada="{ level: 1, pos: 0 }"
          :aria-label="`${language.SHEIN_KEY_PWA_15332}${commentRank}`"
          class="star-html"
        >
          <Star :grade="commentRank" />
        </div>

        <div
          v-if="country"
          class="review-item__info-country"
        >
          <Icon 
            name="sui_icon_location_fill_16px" 
            size="10px"
          />
          <span class="country">{{ country }}</span>
        </div>

        <div
          v-if="isChangeStyle"
          class="review-item__info-time"
          :aria-label="comment.commentTimeNewStyle"
          role="text"
        >
          {{ comment.commentTimeNewStyle }}
        </div>
        <!-- free tips -->
        <span
          v-if="comment.reportId && !isChangeStyle"
          class="report"
          :class="{ 'clamp1': isSlideStyle }"
          aria-hidden="true"
        >
          {{ language.SHEIN_KEY_PWA_15207 }}
        </span>
      </div>

      <div
        v-if="!isChangeStyle"
        class="review-item__info-time"
        :aria-label="comment.commentTimeNewStyle"
        role="text"
      >
        {{ comment.commentTimeNewStyle }}
      </div>
    </div>

    <div 
      v-if="goodsAttr || commentRank"
      class="review-item__line-box"
    >
      <!-- goods attr -->
      <div 
        class="review-item__attr"
        :class="{ 'font-small': isShowAddToCart.show_cart_new }"
      >
        <!-- 星级 -->
        <div
          v-if="isChangeStyle"
          v-ada="{ level: 1, pos: 0 }"
          :aria-label="`${language.SHEIN_KEY_PWA_15332}${commentRank}`"
          class="star-html"
        >
          <Star :grade="commentRank" />
        </div>

        <template v-if="goodsAttr">
          <!-- 颜色尺码 -->
          <span
            class="comment-size"
            tabindex="1"
            role="text"
            :aria-label="goodsAttr"
          >
            {{ goodsAttr }}
          </span>

          <!-- 热门标签 -->
          <span 
            v-if="isShowHotSoldOut"
            class="comment-hottips"
            :class="{ 'light': isHotTipsLight }"
          >{{ commentHotOrSoldOutLabel }}</span>
          <span 
            v-if="isShowAddToCart.show_cart_new && goodsAttr"
            class="comment-addcart-newgap"
          ></span>

          <!-- 加车 -->
          <span
            v-if="isShowAddToCart.show_cart_new"
            class="addcart-common comment-addcart-new"
            :class="{ 'light': isCartLight }"
            @click.stop="addCart"
          >
            <Icon
              class="icon-addcart"
              name="sui_icon_addtocart_12px" 
              size="12px" 
            />
            <span>{{ addCartText }}</span>
            <Icon 
              name="sui_icon_more_right_10px_2" 
              size="10px"
              :is-rotate="GB_cssRight"
            />
          </span>
        </template>
      </div>
      <div 
        v-if="isShowAddToCart.show_cart_old && goodsAttr"
        class="addcart-common comment-addcart" 
        :class="{ 'light': isCartLight }"
        @click.stop="addCart"
      >
        <Icon 
          name="sui_icon_addtocart_20px" 
          size="14px" 
        />
        <span>{{ addCartText }}</span>
      </div>
      <!-- local tag -->
      <!-- <div
        v-if="localTagShow"
        v-expose="{ id: position === 'outer' ? '1-6-7-58' : '1-6-2-58' }"
        class="review-item__local-tag"
      >
        <i class="suiiconfont sui_icon_location_15px location_12px"></i>
        <span>{{ language.SHEIN_KEY_PWA_22717 }}</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import Star from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/Reviews/components/Star/index.vue'
import { calOldGoodsAttr } from 'public/src/pages/goods_detail_v2/utils/common.js'
import { expose } from 'public/src/pages/common/analysis/directive.js'
import { ada } from 'public/src/pages/common/ada/index.js'
const { SiteUID, GB_cssRight } = gbCommonInfo || {}
export default defineComponent({
  name: 'CommentBase',
  directives: { expose, ada },
  inject: ['COMMENT_INSTANCE'],
  components: {
    Star,
    Icon
  },
  props: {
    // 单条评论是否需要展示本地标记
    markSingleLocalComment: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      GB_cssRight
    }
  },
  computed: {
    // 头像 / 国家 任意一个abt命中, 星星、日期、试用报告标签的位置变更
    isChangeStyle() {
      return this.country || this.isShowAvatar
    },
    isShowAvatar() {
      return this.COMMENT_INSTANCE.reviewUserInfoAbt?.User_Avatar
    },
    // isShowCountry() {
    //   // 仅对主商品自己的评论，同款评论展示
    //   const isOutStoreReview = this.COMMENT_INSTANCE.isOutStoreReview
    //   const isFree = this.comment.reportId
    //   return this.country && !isOutStoreReview && !isFree
    // },
    country() {
      return this.comment.userContr
    },
    avatarIconName() {
      const key = this.comment.userIcon || 1
      // const key = Math.floor(Math.random() * 6) + 1
      const iconNameMap = {
        1: 'sui_icon_avatar_blue_1_18px_color',
        2: 'sui_icon_avatar_green_1_18px_color',
        3: 'sui_icon_avatar_yellow_18px_color',
        4: 'sui_icon_avatar_blue_2_18px_color',
        5: 'sui_icon_avatar_green_2_18px_color',
        6: 'sui_icon_avatar_orange_18px_color',
      }

      return iconNameMap[key]
    },
    reviewGoodsInfoAbt() {
      return this.COMMENT_INSTANCE.reviewGoodsInfoAbt
    },
    isShowAddToCart() {
      const defaultShow = {
        show_cart_old: false,
        show_cart_new: false
      }
      const reviewGoodsInfoAbt = this.reviewGoodsInfoAbt
      if(reviewGoodsInfoAbt.goodsinfo_limit_only && this.position === 'outer') { // 参数​​Goodsinfo_limit控制展示场景
        return defaultShow
      }
      const is_show_add_cart = reviewGoodsInfoAbt.is_show_add_cart
      const cart_display_new = reviewGoodsInfoAbt.cart_display_new
      return {
        show_cart_old: is_show_add_cart && !cart_display_new,
        show_cart_new: is_show_add_cart && cart_display_new
      }
    },
    isShowHotSoldOut() {
      const reviewGoodsInfoAbt = this.reviewGoodsInfoAbt
      if(reviewGoodsInfoAbt.goodsinfo_limit_only && this.position === 'outer') {
        return false
      }
      if(reviewGoodsInfoAbt.display_limit) {
        return this.comment.isFirstGoodsId && this.commentHotOrSoldOutLabel
      }
      return this.comment.commentHotOrSoldOutLabel
    },
    isCartLight() {
      const reviewGoodsInfoAbt = this.reviewGoodsInfoAbt
      return reviewGoodsInfoAbt.all_highlight && this.isShowHotSoldOut || reviewGoodsInfoAbt.only_cart_highlight
    },
    isHotTipsLight() {
      const reviewGoodsInfoAbt = this.reviewGoodsInfoAbt
      return reviewGoodsInfoAbt.all_highlight || reviewGoodsInfoAbt.only_goodsinfo_highlight
    },
    addCartText() {
      return  this.reviewGoodsInfoAbt.addtocart_text_new ? this.language.SHEIN_KEY_PWA_37383 || 'Buy the same' : this.language.SHEIN_KEY_PWA_29463
    },
    commentHotOrSoldOutLabel() {
      return this.comment.commentHotOrSoldOutLabel
    },
    goodsAttr() {
      return this.comment.goods_attr || this.calOldGoodsAttr(this.comment, this)
    }, 
    isSlideStyle() {
      return this.COMMENT_INSTANCE.isSlideStyle
    },
    comment() {
      return this.COMMENT_INSTANCE.comment
    },
    language() {
      return this.COMMENT_INSTANCE.language
    },
    position() {
      return this.COMMENT_INSTANCE.position
    },
    showGoodsId() {
      return this.COMMENT_INSTANCE.showGoodsId
    },
    index() {
      return this.COMMENT_INSTANCE.index
    },
    commentDateDisplayNew() {
      return this.COMMENT_INSTANCE.commentDateDisplayNew
    },
    commentRank() {
      const { comment = {} } = this
      const { comment_rank = 0 } = comment
      return Math.min(comment_rank, 5)
    },
    // localTagShow() {
    //   const { markSingleLocalComment, comment } = this
    //   if (!markSingleLocalComment) {
    //     return false
    //   }
    //   return comment.local_flag === 1
    // },
    /**
     * 用户名加密
     * @param {*} txt
     * @returns
     */
    userName() {
      const { comment } = this
      const { user_name, username, isOutStoreReview } = comment
      if (isOutStoreReview) {
        return username
      }
      let txt = user_name || username || ''
      if (txt.indexOf('*') === 1 && txt.lastIndexOf('*') === txt.length - 2) {
        txt = txt.split('*')
        txt = `${txt[0]}***${txt[txt.length - 1]}`
      }
      return txt
    }
  },
  methods: {
    addCart() {
      this.COMMENT_INSTANCE.EVENT_CENTER('addCart', this.comment)
    },
    /**
     * JP time 格式处理
     * @param {*} timeStr
     * @returns
     */
    formatTime(comment) {
      if(this.commentDateDisplayNew && comment.commentTimeNewStyle) return comment.commentTimeNewStyle
      const timeStr = comment.comment_time
      if (SiteUID.includes('jp')) {
        return timeStr.replace(/-/g, '/')
      }
      return timeStr?.split(' ')?.[0]
    },
    /**
     * click callback
     */
    clickGoodsId({ goods_id, jump_goods_id, is_check_skc }) {
      const { COMMENT_INSTANCE, index } = this
      COMMENT_INSTANCE.EVENT_CENTER('clickGoodsId', {
        goods_id: jump_goods_id || goods_id,
        index,
        is_check_skc
      })
    },
    calOldGoodsAttr,
    GB_SHEIN_counting_star_suiicon: typeof window !== 'undefined' ? window.GB_SHEIN_counting_star_suiicon : () => {}
  },
})
</script>
<style lang="less">
.review-item {
  &__info-item {
    width: 100%;
    display: flex;
    align-items: center;
  }
  &__info-avatar {
    margin-right: 0.17rem;
  }
  &__info-name {
    color: #000;
    font-size: 0.32rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 0.16rem;
  }
  &__info-country {
    // flex: 1;
    display: flex;
    align-items: center;
    height: 0.34667rem;
    font-size: 0.24rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
    border-radius: 0.05333rem;
    border: 0.5px solid #000;
    margin-right: 0.11rem;
    padding: 0.01333rem 0.10667rem 0.01333rem 0.05333rem;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .country {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // width: 0;
    }
  }
  &__info-time {
    flex-shrink: 0;
  }
}
.review-item__line-box {
  padding-top: 0.08rem;
  .star-html {
    transform: perspective(400px);
    display: flex;
    align-items: center;
    padding-right: 0.11rem;
  }
  .review-item__attr {
    display: flex;
    flex-wrap: nowrap;
    &.font-small {
      .font-dpr(20px);
      color: var(---sui_color_gray_dark3, #767676);
      line-height: normal;
    }
    .comment-size {
      -webkit-line-clamp: 1;
      word-break: break-all;
    }
  }
  .comment-hottips {
    font-size: 0.26667rem;
    font-weight: 400;
    line-height: normal;
    padding: 0 0.11rem;
    flex-shrink: 0;
    &.light {
      color: var(---sui_color_discount, #FA6338);
      padding: 0;
      margin-left: 0.1067rem;
    }
  }
  .addcart-common {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    color: var(---sui_color_gray_dark3, #767676);
    line-height: normal;
    font-style: normal;
    &.light {
      color: var(---sui_color_discount, #FA6338);
    }
  }
  .comment-addcart-new {
    .font-dpr(20px);
    font-weight: 400;
    &.light {
      color: var(---sui_color_discount, #FA6338);
    }
  }
  .icon-addcart {
    margin-right: 0.05333rem;
  }
  .comment-addcart-newgap {
    display: inline-block;
    width: 1px;
    height: 0.26667rem;
    background-color: var(---sui_color_gray_light2, #BBB);
    margin: 0 0.16rem;
  }
  .comment-addcart {
    gap: 0.05333rem;
    padding: 0.08rem 0.16rem;
    border-radius: 2.66667rem;
    border: 1px solid var(---sui_color_gray_light2, #BBB);
    text-align: center;
    font-size: 0.26667rem;
    font-weight: 510;
    &.light {
      color: #FA6338;
      border: 0.5px solid rgba(250, 99, 56, 0.80);
    }
  }

}
</style>
