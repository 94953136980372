<template>
  <div 
    v-if="showModule"
    class="review-item__membersize-top-content"
    @click.stop
  >
    <div class="review-item__membersize-top-header">
      <div 
        v-if="trueToSizeText"
        class="review-item__true-to-size"
      >
        {{ trueToSizeText }}
      </div>
      <div 
        v-if="memberSizeItem.size_value"
        class="review-item__first-size"
        @click="checkShowMore"
      >
        {{ memberSizeItem.language_name }}:&nbsp;{{ memberSizeItem.size_value }}
        <Icon
          v-show="showMoreIcon && !showMore"
          v-expose="showMoreData"
          class="size-more-icon"
          name="sui_icon_more_circle_down_12px_2"
          color="#222" 
          size="10px" 
        />
      </div>
    </div>
    <div 
      v-show="showMore"
      class="review-item__membersize-top-ul"
    >
      <ul
        v-if="memberSizeData && memberSizeData.leftData.length"
        class="review-item__membersizetop-ul-item"
        aria-hidden="true"
      >
        <li 
          v-for="(item, key) in memberSizeData.leftData" 
          :key="key"
        >
          {{ item.language_name }}:&nbsp;{{ item.size_value }}
        </li>
      </ul>
      <ul
        v-if="memberSizeData && memberSizeData.rightData.length"
        class="review-item__membersizetop-ul-item"
        aria-hidden="true"
      >
        <li 
          v-for="(item, key) in memberSizeData.rightData" 
          :key="key"
        >
          {{ item.language_name }}:&nbsp;{{ item.size_value }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { expose } from 'public/src/pages/common/analysis/directive'
import { Icon } from '@shein-aidc/icon-vue3'
export default defineComponent({
  name: 'CommentSizeNewTop',
  directives: {
    expose,
  },
  components: {
    Icon,
  },
  inject: ['COMMENT_INSTANCE'],
  computed: {
    comment() {
      return this.COMMENT_INSTANCE.comment
    },
    memberSizeNew() {
      return this.comment?.member_size_new || []
    },
    showMore() {
      return this.COMMENT_INSTANCE.showMore
    },
    is_true_to_size() {
      return this.COMMENT_INSTANCE.reviewGoodsInfoAbt.is_true_to_size
    },
    is_size() {
      return this.COMMENT_INSTANCE.reviewGoodsInfoAbt.is_size
    },
    trueToSizeText() {
      const trueToSizeItem = this.memberSizeNew?.find?.(item => item.rule_name_en === 'member_overall_fit') || {}
      return trueToSizeItem.size_value || ''
    },
    showModule() {
      if(!this.memberSizeNew.length) return false
      return this.is_true_to_size && !!this.trueToSizeText || this.is_size
    },
    noTrueToSizeArr() {
      return this.memberSizeNew.filter(item => item.rule_name_en !== 'member_overall_fit') || []
    },
    memberSizeItem() {
      if(!this.memberSizeNew.length) return {}
      return this.noTrueToSizeArr?.[0] || {}
    },
    showMoreIcon() {
      return this.noTrueToSizeArr.length > 1
    },
    memberSizeData() {
      // 创建副本避免修改原数组
      const copyArr = [...this.noTrueToSizeArr]
      copyArr.shift()
      const leftData = []   // 存放偶数索引元素
      const rightData = []  // 存放奇数索引元素
      copyArr.forEach((item, index) => {
        index % 2 === 0 
          ? leftData.push(item) 
          : rightData.push(item)
      })
      return {
        leftData,    // 偶数索引元素数组
        rightData      // 奇数索引元素数组
      }
    },
    showMoreData() {
      return {
        id: '1-6-2-91', 
        data: {
          type: this.comment.comment_id
        },
        code: 'reset-comment-events'
      }
    },
  },
  methods: {
    checkShowMore() {
      if (this.showMoreIcon && !this.showMore) {
        const { COMMENT_INSTANCE } = this
        COMMENT_INSTANCE.UPDATE_STATE('showMore', true)
        COMMENT_INSTANCE.EVENT_CENTER('sizeShowAll', { review_id: this.comment.comment_id })
      }
    },
  },
})
</script>
