<template>
  <div v-if="commentImages.length">
    <!-- 评论图片多列模式 -->
    <div
      v-if="reviewPhotoType === 'None'"
      v-ada="{ level: 1, pos: 0 }"
      role="text"
      :type="language.SHEIN_KEY_PWA_14974"
      :aria-label="language.SHEIN_KEY_PWA_14974"
      class="review-item__inout-wrapper"
      @click.stop
    >
      <template
        v-for="(img, imgIndex) in commentImages"
        :key="imgIndex"
      >
        <div
          v-if="position === 'outer'"
          :style="{
            'background-image': `url(${transformImg({
              img: img.member_image_middle
            })})`
          }"
          data-design-width="240"
          class="review-item__inout-item-img"
          state="comment-img"
          @click="showReviewImg(imgIndex, comment)"
        ></div>
        <div
          v-else
          :data-background-image="
            transformImg({ img: img.member_image_middle })
          "
          data-design-width="240"
          class="review-item__inout-item-img lazy lazyload"
          state="comment-img"
          @click="showReviewImg(imgIndex, comment)"
        ></div>
      </template>
    </div>
    <!-- 评论图片单列模式 -->
    <div 
      v-else-if="reviewPhotoType === 'Smallpic'"
      @click.stop
    >
      <div
        :data-background-image="
          transformImg({ img: commentImages[0].member_image_middle })
        "
        data-design-width="240"
        class="review-item__inout-one-img lazy lazyload"
        state="comment-img"
        @click="showReviewImg(0, comment)"
      >
        <div
          v-if="(commentImages.length > 0 && position === 'outer') || (commentImages.length > 1 && position === 'inner')"
          class="review-item__inout-one-text"
        >
          {{ `${commentImages.length}` }}
        </div>
      </div>
    </div>
    <!-- 评论多图混合模式 商详评论楼层去除懒加载机制 -->
    <div
      v-else-if="reviewPhotoType === 'Varypic'"
      class="review-item__vary-wrapper"
    >
      <div
        :data-background-image="position === 'outer' ? null : transformImg({ img: commentImages[0].member_image_middle })"
        :style="imageStyle"
        class="review-item__vary-item"
        :class="imageClass('left')"
        state="comment-img"
        @click="showReviewImg(0, comment)"
      ></div>
      <div
        v-if="commentImages.length > 1"
        class="review-item__right"
      >
        <template v-for="(img, imgIndex) in commentImages">
          <div
            v-if="imgIndex > 0 && imgIndex < 3"
            :key="imgIndex"
            :data-background-image="position === 'outer' ? null : transformImg({ img: img.member_image_middle })"
            :style="imageStyle"
            :class="imageClass('right')"
            class="review-item__vary-item"
            state="comment-img"
            @click="showReviewImg(imgIndex, comment)"
          ></div>
        </template>
      </div>
      <div
        v-if="commentImages.length > 3"
        class="review-item__other-count"
      >
        {{ commentImages.length - 3 }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { transformImg } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { ada } from 'public/src/pages/common/ada/index.js'
export default defineComponent({
  name: 'CommentImages',
  inject: ['COMMENT_INSTANCE'],
  directives: {
    ada,
  },
  props: {
    reviewPhotoType: {
      type: String,
      default: 'None'
    },
  },
  computed: {
    imageStyle() {
      return (img) => {
        return this.position === 'outer' ? {
          'background-image': `url(${transformImg({
            img: img.member_image_middle
          })})`
        } : {}
      }
    },
    imageClass() {
      return (type) => {
        // 商详评论楼层去除懒加载机制
        let className = [this.position === 'outer' ? '' : 'lazy lazyload']
        if(type === 'left') {
          let largeImg = this.commentImages.length === 1 || this.commentImages.length > 2
          className.push(largeImg ? 'review-item__vary-item-large' : 'review-item__vary-item-medium')
        } else if(type === 'right') {
          className.push(this.commentImages.length > 2 ? 'review-item__vary-item-small' : 'review-item__vary-item-medium')
        }
        return className
      }
    },
    comment() {
      return this.COMMENT_INSTANCE.comment
    },
    language() {
      return this.COMMENT_INSTANCE.language
    },
    position() {
      return this.COMMENT_INSTANCE.position
    },
    commentImages() {
      // 是否展示水印图片
      const { showCommentWatermark = false } =
        this.COMMENT_INSTANCE?.watermarkConfig || {}
      return (
        this.comment?.comment_image?.filter(_ => {
          if (
            showCommentWatermark &&
            _?.member_image_middle &&
            _.member_image_middle?.indexOf('_wk_shein') === -1 &&
            _.member_image_middle?.indexOf('images3') > -1
          ) {
            const suffix = _?.member_image_middle?.split('.')?.pop() || ''
            _.member_image_middle = suffix
              ? _?.member_image_middle.replace(
                  `.${suffix}`,
                  `_wk_shein.${suffix}`
              )
              : _?.member_image_middle
          }

          // 临时兼容低版本webp图片兼容问题 - compliantWebpImg
          return {
            ..._,
            member_image_middle: _?.member_image_middle?.replace('.webp', '.jpg')
          }
        }) || []
      )
    }
  },
  methods: {
    showReviewImg(index, comment) {
      const { COMMENT_INSTANCE } = this
      COMMENT_INSTANCE.EVENT_CENTER('imageClick', {
        report_id: comment.reportId,
        comment_id: comment.comment_id,
        index
      })
      let type = ''
      if(this.position === 'outer'){
        type = `${comment.comment_id}`
      }else if(this.position === 'inner') {
        // 评论详情页上报：review id+图片总数+点击的张数
        type = `${comment.comment_id}\`${comment.comment_image.length}\`${index + 1}`
      }
      const { commentHotOrSoldOutLabelType, isFirstGoodsId } = comment
      const { reviewGoodsInfoAbt } = this.COMMENT_INSTANCE
      daEventCenter.triggerNotice({
        daId: '1-6-2-81',
        extraData: {
          type,
          is_almostsoldout: +!!(reviewGoodsInfoAbt.display_limit ? (isFirstGoodsId && commentHotOrSoldOutLabelType == 1) : commentHotOrSoldOutLabelType == 1), //是否有低库存，1=有，0=无
          is_hotsell: +!!(reviewGoodsInfoAbt.display_limit ? (isFirstGoodsId && commentHotOrSoldOutLabelType == 2) : commentHotOrSoldOutLabelType == 2), //是否有是否有热销，1=有，0=无
          is_addtocart: +!!reviewGoodsInfoAbt.is_show_add_cart, //是否有加车，1=有，0=无
        }
      })
    },
    transformImg,
    iconClick(index, comment) {
      if(this.position !== 'outer'){
        this.showReviewImg(index, comment)
      }
    },
    stopEvent(e) {
      if(this.position !== 'outer'){
        e.stopPropagation()
      } 
    }
  },
})
</script>
