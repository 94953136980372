<template>
  <div
    v-if="showSHEIN"
    ref="SHEINerZone"
    v-expose="{ id: '1-6-2-65',data: { Imgnum: allCount, page_name: isReviewDetail ? 'page_goods_reviews' : '' }}"
    class="sheiner-zone"
    @click.stop
    @touchend="touchChange()"
  >
    <template v-if="isReviewDetail">
      <div
        class="sheiner-zone__title sheiner-zone__title-new"
      >
        <div
          ref="SHEINerTitle"
          class="sheiner-zone__sub-title"
          @click="openReviewGallery(1)"
        >
          {{ language.SHEIN_KEY_PWA_37167 || 'Review Pics' }}({{ allCount }})
        </div>
        <div
          class="sheiner-zone__viewall"
          @click="openReviewGallery(3)"
        >
          {{ language.SHEIN_KEY_PWA_15040 }}
          <i
            class="view-review__all-hook suiiconfont sui_icon_more_right2_16px"
          ></i>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        v-if="!hiddenTitle"
        class="sheiner-zone__title"
        tabindex="1"
        @click="openSHEINer(-1)"
      >
        <div
          ref="SHEINerTitle"
          class="sheiner-zone__sub-title"
        >
          <template v-if="!isEllipsis">
            {{ language.SHEIN_KEY_PWA_31776 }}
          </template>
          <template v-else>
            {{ language.SHEIN_KEY_PWA_31777 }}
          </template>
        </div>
        <div>({{ allCount }})</div>
      </div>
    </template>
    <div
      :class="{
        'products-box-scroll': true,
        'products-box-scrollSingleLine': true
      }"
    >
      <div 
        ref="SHEINerScrollBox"
        class="scroll-box"
      >
        <!-- 商品项 -->
        <div
          v-for="(item, index) in renderList"
          :key="item.comment_id"
          v-expose="isReviewDetail ? {} : getExpoedData(item, index)"
          class="sheiner-zone__item_wrapper"
          :class="{
            'sheiner-zone__item_wrapper_mid': midLayout,
            'sheiner-zone__item_wrapper_small': smallLayout
          }"
        >
          <img
            :data-src="transformImg({ img: item.firstImg })"
            :src="LAZY_IMG"
            data-design-width="200"
            class="lazy j-push-history-hash lazyload"
            :data-image-url="item.firstImg"
            :data-index="index + 1"
            @click="showReviewImg(index, item)"
          />
          <div
            v-if="isReviewDetail && item.comment_image.length > 1"
            class="sheiner-zone__item-num"
          >
            +{{ item.comment_image.length }}
          </div>
        </div>
        <div
          v-if="allCount > 10 || showViewAll"
          class="sheiner-zone__mask"
          :class="{
            'sheiner-zone__item_wrapper_mid': midLayout,
            'sheiner-zone__item_wrapper_small': smallLayout
          }"
          @click="openSHEINer(-1, 3)"
        >
          <sui_icon_viewmore_right_40px
            size="40px"
            :is-rotate="GB_cssRight"
          />
          <p class="sheiner-zone__view-more">
            {{ language.SHEIN_KEY_PWA_22253 }}
          </p>
        </div>
      </div>
    </div>
    <GalleryPopup
      v-if="gallery_pop_showed"
      v-model="isShow"
      :language="language"
      :curId="curId"
    />
    <ReviewGalleryPopup
      v-if="review_gallery_pop_show_sync && isReviewDetail"
      v-model="isShowReviewGallery"
      :allCount="allCount"
      :language="language"
    />
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
import { expose } from 'public/src/pages/common/analysis/directive'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { sui_icon_viewmore_right_40px } from '@shein-aidc/icon-vue3'
import commentMixin from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/Reviews/mixin/comment.js'
const { LAZY_IMG, GB_cssRight } = gbCommonInfo
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { defineAsyncComponent } from 'vue'
import { transformImg } from '@shein/common-function'

const POSITION = 'out-gallery'
const POSITION_REVIEW = 'review-gallery'
export default defineComponent({
  name: 'SHEINerZone',
  directives: {
    expose,
  },
  components: {
    GalleryPopup: defineAsyncComponent(() => import(/* webpackChunkName: "GalleryPopup" */ './GalleryPopup')),
    ReviewGalleryPopup: defineAsyncComponent(() => import(/* webpackChunkName: "ReviewGalleryPopup" */ './ReviewGalleryPopup')),
    sui_icon_viewmore_right_40px,
  },
  mixins: [commentMixin],
  props: {
    position: {
      type: String,
      default: ''
    },
    mustShowTitle: {
      type: Boolean,
      default: false
    },
    hasPadding: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      LAZY_IMG,
      GB_cssRight,
      viewAllBtnClassName: 'view-all-handler',
      isShow: false,
      curId: '',
      isTouch: false,
      isEllipsis: false,
      showViewAll: false,

      reviewGalleryPopShowed: false,
      isShowReviewGallery: false
    }
  },
  computed: {
    ...mapGetters('productDetail/common', ['language_v2', 'product_relation_id']),
    ...mapGetters('productDetail/CustomerGallery', [
      'review_abt',
      'customer_show_list',
      'customer_show_list_review',
      'all_customer_gallery_sum',
      'buyer_show_with_image_total',
      'show_SHEINer'
    ]),
    ...mapState('productDetail/CustomerGallery', [
      'customer_show'
    ]),

    ...mapState('productDetail/CommentPopup', [
      'comment_info_data_for_img',
      'review_gallery_pop_show',
      'review_gallery_pop_show_sync',
      'gallery_pop_showed'
    ]),
    showSHEIN() {
      let count = 0
      if(this.isReviewDetail) {
        count = 5
      }
      return this.allCount > count
    },
    
    allCount() {
      return this.isReviewDetail ? this.buyer_show_with_image_total : this.all_customer_gallery_sum
    },
    language() {
      return this.language_v2
    },
    renderList() {
      if(this.isReviewDetail) {
        return this.customer_show_list_review.slice(0, 10)
      }
      return this.customer_show_list.slice(0, 10)
    },
    isReviewDetail() {
      return this.position === 'reviewDetail'
    },
    midLayout() {
      if(this.isReviewDetail) {
        return true
      }
      return ['MiddlePic', 'MiddlePic_MoveBack', 'MiddlePic_WithReview'].includes(
        this.review_abt.galleyLayout
      )
    },
    smallLayout() {
      return ['SmallPic', 'SmallPic_MoveBack', 'SmallPic_WithReview'].includes(
        this.review_abt.galleyLayout
      )
    }, 
    hiddenTitle() {
      if(this.isReviewDetail) {
        return false
      }
      return !this.mustShowTitle && ['MiddlePic_WithReview', 'SmallPic_WithReview'].includes(
        this.review_abt.galleyLayout
      )
    },
    // title() {
    //   return this.language.SHEIN_KEY_PWA_31776 + ` (${this.all_customer_gallery_sum})`
    // }
  },
  watch: {
    async review_gallery_pop_show(newVal) {
      if(newVal && !this.review_gallery_pop_show_sync) {
        this.update_review_gallery_pop_show_sync(newVal)
        await nextTick()
      }

      this.isShowReviewGallery = newVal
    },
    customer_show(newVal) {
      this.isShow = newVal
    },
    product_relation_id() {
      this.update_cur_page_for_img(1)
      this.fetch_comment_info_for_img({ params: { source: this.isReviewDetail ? 'overViewPic' : '' } })
    }
  },
  mounted() {
    this.update_cur_page_for_img(1)
    this.fetch_comment_info_for_img({ params: { source: this.isReviewDetail ? 'overViewPic' : '' } })
    this.isEllipsis =
      this.$refs['SHEINerTitle']?.scrollWidth >
      this.$refs['SHEINerTitle']?.offsetWidth
  },
  methods: {
    ...mapMutations('productDetail/CustomerGallery', ['update_customer_show']),
    ...mapMutations('productDetail/CommentPopup', [
      'update_comment_info_data_for_img',
      'update_gallery_pop_showed',
      'update_review_gallery_pop_show_sync',
      'update_review_gallery_pop_show',
      'update_cur_page_for_img',
    ]),
    ...mapActions('productDetail/CommentPopup', ['fetch_comment_info_for_img']),
    transformImg,
    showReviewImg(index, comment) {
      const position = this.isReviewDetail ? POSITION_REVIEW : POSITION
      this.imageClickHandle(
        {
          report_id: comment.reportId,
          comment_id: comment.comment_id,
          index: 0 // 因为列表展示的都是第一张
        },
        position
      )
      this.clickCusgalleryReport(2)
      daEventCenter.triggerNotice({
        daId: '1-6-2-69',
        extraData: {
          review_location: 'page_goods_cusgallery',
          img_list: this.getExposeDataImgList(comment, index)
        }
      })
    },
    changeHash(value) {
      location.hash = value ? '#' + value : ''
    },
    // 弹出弹窗
    openSHEINer(id, type) {
      if(this.isReviewDetail) {
        this.openReviewGallery(4)
        return
      }
      this.curId = id
      this.changeHash('customer-show')
      if (!this.gallery_pop_showed) {
        // init
        this.update_comment_info_data_for_img([])
      }
      this.update_gallery_pop_showed(true)
      this.update_customer_show(true)
      const click_type = type ? 3 : id === -1 ? 1 : 2
      this.clickCusgalleryReport(click_type)
    },
    // 弹窗评论买家秀弹窗
    openReviewGallery(type) {
      this.changeHash('review-customer')
      this.clickCusgalleryReport(type)
      // this.isShowReviewGallery = true
      this.update_review_gallery_pop_show(true)

    },
    touchChange() {
      if (this.isTouch) return
      this.isTouch = true
      if (this.isReviewDetail) return
      daEventCenter.triggerNotice({
        daId: '1-6-2-68'
      })
    },
    clickCusgalleryReport(click_type) {
      daEventCenter.triggerNotice({
        daId: '1-6-2-67',
        extraData: {
          click_type,
          page_name: this.isReviewDetail ? 'page_goods_reviews' : ''
        }
      })
    },
    // 获取上报参数img_list
    getExposeDataImgList(item, index) {
      const { type = '', comment_id, reportId = '' } = item
      let eId = ''
      let typeNum = -1
      if (type === 'gallery') {
        item.imageType === 'review' ? typeNum = 4 : typeNum = 1
      } else if (reportId) {
        typeNum = 2
        eId = reportId
      } else {
        typeNum = 3
        eId = comment_id
      }
      return `${eId}\`${typeNum}\`${index + 1}`
    },
    getExpoedData(item, index) {
      const { type = '', comment_id, reportId = '' } = item
      let eId = ''
      let typeNum = -1
      if (type === 'gallery') {
        item.imageType === 'review' ? typeNum = 4 : typeNum = 1
      } else if (reportId) {
        typeNum = 2
        eId = reportId
      } else {
        typeNum = 3
        eId = comment_id
      }
      const img_list = `${eId}\`${typeNum}\`${index + 1}`
      const data = {
        id: '1-6-2-66',
        code: 'gallery',
        data: {
          img_list
        }
      }
      return data
    }
  },
})
</script>

<style lang="less">
.sheiner-zone {
  background: #fff;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  padding-bottom: 0.32rem;
  &__title {
    padding-top: 0.2667rem;
    margin: 0 0.32rem;
    .flexbox;
    justify-content: flex-start;
    line-height: 0.51rem;
    .font-dpr(28px);
    font-weight: bold;
    gap: 0.1067rem;
  }
  &__title-new {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.16rem;
  }
  &__viewall {
    color: #666;
    text-align: right;
    font-size: 0.32rem;
    font-style: normal;
    font-weight: 590;
    line-height: normal;
  }
  &__item-num {
    position: absolute;
    bottom: 0.05rem;
    right: 0.05rem;
    color: #FFF;
    text-align: center;
    font-size: 0.26667rem;
    font-style: normal;
    font-weight: 510;
    line-height: normal;
    display: inline-flex;
    padding: 0.05333rem 0.16rem;
    align-items: center;
    gap: 0.10667rem;
    border-radius: 2.66667rem;
    background: var(---sui_color_gray_alpha60, rgba(0, 0, 0, 0.60));
  }
  &__sub-title {
    // flex:1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__item_wrapper {
    width: 2.9733rem;
    height: 3.96rem;
    border-radius: none;
    position: relative;
    &:not(:first-child) {
      margin-left: 0.1067rem;
    }
    &:first-child {
      margin-left: 0.32rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__mask {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 2.9733rem;
    height: 3.96rem;
    background: var(---sui_color_gray_weak2a, #fafafa);
    border-radius: 0.106666rem;
    margin-left: 0.21rem;
    margin-right: 0.32rem;
  }
  &__item_wrapper_mid {
    width: 2.0267rem;
    height: 2.0267rem;
    border-radius: 0.0533rem;
    overflow: hidden;
    // > .sui_icon_viewmore_right_40px{
    //   line-height: 1.0667rem;
    // }
    &:not(:first-child) {
      margin-left: 0.1067rem;
    }
    .sheiner-zone__view-more{
      font-size: 0.32rem;
      font-weight: 700;
    }
  }
  &__item_wrapper_small {
    width: 1.3867rem;
    height: 1.3867rem;
    border-radius: none;
    // > .sui_icon_viewmore_right_40px{
    //   font-size: 0.8533rem !important;
    //   line-height: 0.8533rem;
    // }
    .sheiner-zone__view-more{
      font-size: 0.2677rem;
    }
    &:not(:first-child) {
      margin-left: 0.1067rem;
    }
  }
}
// 横滑推荐商品栏
.products-box-scroll {
  width: calc(100% + 0.64rem);
  overflow-x: scroll;
  margin-top: 0.16rem;
  display: flex;
  position: relative;
  left: -0.32rem;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .scroll-box {
    display: flex;
    position: relative;
    padding: 0 0.32rem;
  }
}
.products-box-scrollSingleLine {
  .scroll-box {
    flex-wrap: nowrap;
  }
  .product-item-ctn {
    margin-right: 0.213333rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
