<template>
  <div
    class="review-label"
    :class="{
      'main': styleType == 'main',
      'loading': loading
    }"
  >
    <div
      ref="reviewLabelContent"
      class="review-label__container j-expose__review-label__container"
      :class="{
        [`review-label__container-${reviewTagLine}`]: true,
        'review-label__container-down': dropdown,
        'show-store-review': showStoreReview
      }"
      @transitionend.once="transitionend"
      @webkitTransitionEnd.once="transitionend"
    >
      <template v-for="(item, index) in tagList">
        <span
          v-if="index < 10"
          :key="index"
          ref="reviewLabelItem"
          class="review-label__item j-expose__review-label__item"
          :class="{ 
            'active': activeId == item.tagId && !showLabelColor , 
            'active-label': activeId == item.tagId && showLabelColor , 
            'is-ar': activeId == item.tagId && cssRight,
            'review-label__item-color': showLabelColor 
          }"
          :data-id="item.tagId"
          :data-expose-id="`review-label__${item.tagId}`"
          @click.stop="handleLabelClick(item.tagId)"
        >
          <span 
            class="j-expose__review-label__con review-label__item-text"
          >
            <span v-expose="getExposeData(item.tagId, index)"> {{ item.tagName }} ({{ item.tagCmtNumShow }})</span>
          </span>
          <transition name="morebtnfade">
            <span
              v-if="index === currentPositonIndex && showMoreIconRight"
              class="review-label__new-more"
              @click.stop="handleClickMore"
            >
              <Icon
                :name="dropdown ? 'sui_icon_more_up_16px' : 'sui_icon_more_down_16px'"
                size="16px"
                is-responsive-name
              />
            </span>
          </transition>
        </span>
      </template>
    </div>
    <!-- more icon -->
    <p
      v-show="showMoreIcon"
      class="review-label__more"
    >
      <i
        class="review-label__more-icon iconfont"
        :class="dropdown ? 'icon-shang' : 'icon-down'"
        @click="handleClickMore"
      ></i>
    </p>
  </div>
</template>

<script>
import { expose } from 'public/src/pages/common/analysis/directive'
import { defineComponent, nextTick } from 'vue'
// import { abtservice } from 'public/src/services/abt'
import { webScrollExpose } from 'public/src/pages/common/analysis/ScrollExpose.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import {  mapGetters } from 'vuex'
import { Icon } from '@shein-aidc/icon-vue3'
const { GB_cssRight } = gbCommonInfo

export default defineComponent({
  name: 'ReviewLabel',
  directives: {
    expose,
  },
  components: {
    Icon,
  },
  props: {
    /**
     * 枚举；
     * 1. main (商详页主页面)
     * 2. list（评论列表）
     */
    styleType: {
      type: String,
      default: 'main'
    },
    // one, two, three: 评论标签展示行数
    reviewTagLine: {
      type: String,
      default: 'three'
    },
    tagList: {
      type: Array,
      default: () => []
    },
    activeId: {
      type: String,
      default: ''
    },
    sourcePage: { // 页面来源
      type: String,
      default: 'detail'
    },
    loading: {
      type: Boolean,
      default: false
    },
    showStoreReview: {
      type: Boolean,
      default: false
    },
    isShow: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      cssRight: GB_cssRight,
      showMoreIcon: false,
      showMoreIconRight: false,
      dropdown: false,
      viewAreaLastItemIndex: 0,
      currentPositonIndex: 0,
    }
  },
  computed: {
    ...mapGetters('productDetail/common', [
      'fs_abt',
    ]),
    showLabelColor() {
      return this.fs_abt?.Reviewchange?.param?.Labelcolor === 'New'
    },
  },
  watch: {
    tagList(nValue, oValue) {
      const bol = nValue.every((item) => {
        const r = oValue.find(i => i.tagId == item.tagId)
        return !!r
      })
      !bol && this.init()
    },
    isShow: {
      immediate: true,
      handler() {
        nextTick(() => {
          this.tagIntoView()
        })
      }
    },
  },
  mounted() {
    this.init()
  },
  beforeUnmount() {
    // 移除监听订阅
    webScrollExpose.removeSubscriber(this.$refs['reviewLabelItem'])
  },
  methods: {
    tagIntoView(){
      if (typeof window == 'undefined') {
        return
      }
      const element = document.querySelector('.review-label__item.active-label')
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth', // 平滑滚动
          block: 'nearest', // 最接近的边缘
          inline: 'start'
        })
      }
    },
    getExposeData(id) {
      const data = {
        id: '1-6-2-116',
        code: 'review_filter',
        data: {
          filter_list: {
            type: 6,
            text_subtype: id
          }
        },
      }
      return data
    },
    init() {
      // 商详评论仅显示一行标签，评论详情页根据abt配置显示n行标签，超过n行显示更多按钮
      this.styleType === 'list' && this.calcShowMore()
      this.sourcePage === 'detail' && this.handleExposeAnalysis()
    },
    handleLabelClick(id) {
      if (id != this.activeId) {
        daEventCenter.triggerNotice({
          daId: this.styleType === 'main' ? '1-6-7-31' : '1-6-2-31',
          extraData: {
            styleType: this.styleType,
            id
          }
        })
      }

      this.$emit('reviewTagIdChange', id)
    },
    // 计算是否显示显示更多按钮
    calcShowMore() {
      nextTick(() => {
        const el = this.$refs['reviewLabelContent']
        const contentBottom = el.getBoundingClientRect().bottom

        const doms = this.$refs['reviewLabelItem']
        if(doms.length){
          if(['one', 'two'].includes(this.reviewTagLine)){
            // 评论详情页一、二行的更多按钮跟在标签后面
            let index = doms.findIndex(item => {
              const itemBottom = item.getBoundingClientRect().bottom
  
              // 说明item超过了内容区域，需要显示更多按钮
              this.showMoreIconRight = itemBottom > contentBottom
              return itemBottom > contentBottom
            })
            // 标签折叠时，可视区域最后一个元素的索引
            this.viewAreaLastItemIndex = index > 1 ? index - 1 : 0

            if(this.showMoreIconRight){
              this.currentPositonIndex = this.dropdown ? this.tagList.length - 1 : this.viewAreaLastItemIndex
            }
          }else if(this.reviewTagLine === 'three'){
            // 三行的更多按钮显示在最下方单独占一行
            let lastItem = doms[doms.length - 1]
            let lastItemBottom = lastItem.getBoundingClientRect().bottom
            this.showMoreIcon = lastItemBottom > contentBottom
          }
        }
        // 更多按钮曝光上报
        this.exposeMoreIcon()
      })
    },
    exposeMoreIcon() {
      if(this.showMoreIcon || this.showMoreIconRight){
        daEventCenter.triggerNotice({
          daId: '1-6-2-89',
        })
      }
    },
    handleClickMore() {
      daEventCenter.triggerNotice({
        daId: this.styleType === 'main' ? '1-6-7-32' : '1-6-2-90',
      })
      this.dropdown = !this.dropdown
      if(this.showMoreIconRight){
        this.currentPositonIndex = this.dropdown ? this.tagList.length - 1 : this.viewAreaLastItemIndex
      }
      if(!this.dropdown){
        // 收缩后滚动到顶部
        nextTick(() => {
          this.$refs['reviewLabelContent'].scrollTo(0, 0)
        })
      }
    },
    transitionend() {
      webScrollExpose.triggerScroll()
    },
    // 获取tag下评论数展示
    getTagCommentNumStr(tagItem) {
      const { tagCmtNum, fuzzy, numStr } = tagItem || {}
      if (fuzzy) {
        return numStr
      }
      return tagCmtNum || 0
    },
    // 曝光埋点处理
    handleExposeAnalysis() {
      nextTick(() => {
        webScrollExpose.subscribe({
          dom: this.$refs['reviewLabelItem'],
          mergeExpose: true,
          middleJudge: (el) => {
            return webScrollExpose.withInExposeArea(el, this.$refs['reviewLabelContent'])
          },
          callback: ({ container }) => {
            daEventCenter.triggerNotice({
              daId: this.styleType === 'main' ? '1-6-7-30' : '1-6-2-30',
              target: container,
              extraData: {
                styleType: this.styleType,
              }
            })
          }
        })
      })
    }
  },
})
</script>

<style lang="less" scoped>
.review-label {
  padding: 0.32rem 0.32rem 0.107rem;
  // border-bottom: 1px solid #E5E5E5;
  &.main {
    border-bottom: none;
    padding-top: 0;
    padding-bottom: 0;
  }
  &.loading {
    pointer-events: none;
  }

  &__container {
    overflow: hidden;
    transition: max-height .4s;
    &.show-store-review{
      display: flex;
      flex-wrap: nowrap;
      overflow: auto;
      overflow-y: hidden;
      padding-right: 0;
      .review-label__item{
        color: #000;
        &.active-label {
          color: var(---sui_color_micro_emphasis, #A86104);
        }
      }
      &::-webkit-scrollbar{
        display:none
      }
    }
  }
  
  /* 问题暂存， 发现这里会导致只显示一行评论标签！！！ */
  &__container-one {
    max-height: 0.72rem + 0.2133rem;
    padding-right: 16px;
  }
  &__container-two {
    max-height: 0.72rem * 2 + 0.2133rem;
    padding-right: 16px;
  }
  &__container-three {
    max-height: 0.72rem * 3 + 0.2133rem * 2;  // 要求最多显示三行
  }
  &__container-down {
    max-height: 5rem;
    overflow-y: scroll;
  }

  &__item {
    flex: 0 0 auto;
    display: inline-block;
    height: 0.72rem;
    line-height: 0.72rem;
    padding: 0 0.32rem;
    .margin-r(0.2133rem);
    margin-bottom: 0.2133rem;
    background-color: #f6f6f6;
    border: 1px solid #f6f6f6;
    .font-dpr(24px);
    color: #666;
    position: relative;
    /*rw:begin*/
    background-color: #fff;
    color: #9B9B9B;
    border-color: #E5E5E5;
    border-radius: 0.36rem;

    &.active {
      position: relative;
      border-color: #222;
      color: #222;
      /*rw:begin*/
      border-color: #FF9999;
      color: #FF9999;

      &::before {
        position: absolute;
        top: 0;
        .right(-1px);
        content: '';
        width: .4rem;
        height: .4rem;
        display: inline-block;
        /*sh:begin*/
        background: url(/pwa_dist/images/filter-selected-457931bc64.png);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    &.is-ar {
      &::before {
        transform: rotate(90deg);
      }
    }
  }
  &__item-text {
    max-width: 8.08rem;
    display: inline-block;
    .text-overflow();
  }
  &__item-color{
    background: var(---sui_color_micro_emphasis_bg, #FFF8EB);
    color: #666666;
    border: none;
    &.active-label {
      color: var(---sui_color_micro_emphasis, #A86104);

    }
  }

  &__more {
    text-align: center;
    margin: .1rem 0 .048rem
  }

  &__more-icon {
    display: inline-block;
    width: 50%;
    color: #999;
  }
  
  &__new-more{
    color: #999;
    position: absolute;
    right: -0.63667rem;
  }
  .morebtnfade-enter-active, .morebtnfade-leave-active {
    transition: opacity 0.4s;
  }
  .morebtnfade-enter-from, .morebtnfade-leave-to {
    opacity: 0;
  }
}
</style>
