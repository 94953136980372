/*
* 评论工具类
*/

// review_sku_list评论返回的skulist，skuList为当前商品的所有sku集合
export const matchReviewSkuCode = (review_sku_list = [], skuList = []) => {
  if(skuList.length === 1) {
    return skuList[0]?.sku_code || ''
  }
  if(!review_sku_list.length || !skuList.length) {
    return ''
  }
  const need_match_sku = review_sku_list.filter(item => item.attribute_name_id !== '27') || []
  if(!need_match_sku.length) {
    return ''
  }
  let match_item = null
  for(let i = 0; i < skuList.length; i++) {
    const skuItem = skuList[i]
    const sku_sale_attr = skuItem.sku_sale_attr || []
    if(sku_sale_attr.length === 0) {
      continue
    }
    const isMatch = sku_sale_attr.every(sku => { // 每个sku的attr_id和attr_value_id属性都在need_match_sku
      const match_sku = need_match_sku.find(need_match_sku_item => need_match_sku_item.attribute_name_id === sku.attr_id && need_match_sku_item.attribute_value_id === sku.attr_value_id)
      return match_sku
    })
    if(isMatch) {
      match_item = skuItem
      break
    }
  }
  return match_item?.sku_code || ''
}
