<template>
  <div 
    class="view-review__store-content"
    :class="{ 'in_review_list': sourcePage === 'page_goods_review' }"
    v-if="storeReviewInfo.shop_tag?.length >= 2"
    v-expose="{
      id: sourcePage === 'page_goods_review' ? '1-6-2-104' : '1-6-2-102',
      data: {
        revnum: storeReviewInfo.shopCmtNum,
        keywordnum: storeReviewInfo.shop_tag?.length,
        benefit: storeReviewInfo?.has_benefit_point ? 1 : 0
      }
    }"
    @click.stop=""
  >
    <div class="view-review__store-header" @click.stop="enterStoreReview">
      <b class="left-store-nums">{{ (language_v2.SHEIN_KEY_PWA_35090 || 'Store Reviews') }} ({{ storeReviewInfo.shopCmtNum }})</b>
      <div class="right-arrow-text">
        <span class="right-text-content">{{ storeReviewInfo.storeCommentTips }}</span>
        <Icon 
          name="sui_icon_more_right_12px" 
          size=".32rem" 
          class="more-right-icon"
          :is-rotate="GB_cssRight"
        />
      </div>
    </div>
    <div class="view-review__store-tag-list">
      <div
        v-for="(item, index) in storeReviewInfo.shop_tag || []"
        :key="index"
        class="view-review__store-tag-item"
      >
        <span @click.stop="enterStoreReview(item)">{{ `${item.tagName}(${item.tagCmtNum})` }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { expose } from 'public/src/pages/common/analysis/directive'
import { defineComponent } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { mapGetters, mapMutations } from 'vuex'
import { Icon } from '@shein-aidc/icon-vue3'
import { jumpToStorePage } from 'public/src/pages/store_pages/js/utils.js'
const { GB_cssRight } = gbCommonInfo

export default defineComponent({
  name: 'StoreReview',
  directives: {
    expose,
  },
  components: {
    Icon,
  },
  props: {
    sourcePage: { // 页面来源
      type: String,
      default: 'page_goods_detail'
    },
    storeReviewInfo: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters('productDetail/common', [
      'fs_abt',
      'language_v2',
      'store_info',
      'goods_id'
    ])
  },
  mounted() {
  },
  methods: {
    enterStoreReview(tag){
      daEventCenter.triggerNotice({
        daId: this.sourcePage === 'page_goods_review' ? '1-6-2-105' : '1-6-2-103',
        extraData: {
          location: !!tag?.tagId ? 1 : 0,
          revnum: this.storeReviewInfo?.shopCmtNum,
          keywordnum: this.storeReviewInfo.shop_tag?.length,
          benefit: this.storeReviewInfo?.has_benefit_point ? 1 : 0
        }
      })
      let { store_info, goods_id} = this
      const querys = {
        store_code: store_info.store_code || '',
        store_name: store_info.title || '',
        tabId: 'review',
        showReviewTips: 1,
        shopTagJumpFlag: !!tag?.tagId ? 2 : 1,
        goodId: goods_id
      }
      if(tag?.tagId){ // 点击的标签tag，需携带reviewtagId
        querys.reviewtagId = tag?.tagId
      }
      jumpToStorePage(querys, { useHref: true })
    }
  }
})
</script>

<style lang="less">
.page_goods_review_for_store{
  border-bottom: 1px solid #E5E5E5;
  background: #fff;
  &.is-last-review{
    border-bottom: none;
    padding-bottom: 0.9rem
  }
}

.view-review {
  &__store-content{
    padding: 0.373rem 0;
    border-top: 1px solid #E5E5E5;
    &.in_review_list{
      border-top: none;
    }
  }
  &__store-header{
    display:flex;
    justify-content: space-between;
    padding: 0 0.32rem 0.32rem;
    color: #222222;
    .left-store-nums{
      white-space: nowrap;
    }
    .right-arrow-text{
      display: flex;
      align-items: center;
      color: #666666;
      padding-left: 0.106667rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .right-text-content{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .more-right-icon{
        color: #767676;
      }
    }
  }
  &__store-tag-list{
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    padding: 0 0.32rem;
    &::-webkit-scrollbar{
      display:none
    }
  }
  &__store-tag-item{
    padding: 0.16rem 0.32rem;
    border-radius: 2px;
    background: #FFF8EB;
    margin-right: 0.213rem;
    flex: 0 0 auto;
    color: #000000;
    max-width: 8.08rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:last-child{
      margin-right: 0;
    }
  }
}

</style>
