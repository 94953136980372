<template>
  <div
    v-if="comment.reply && position === 'inner'"
    class="review-item__reply"
    @click.stop
  >
    <h4>{{ language.SHEIN_KEY_PWA_15023 }}</h4>
    <p v-html="comment.reply.content"></p>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CommentReply',
  inject: ['COMMENT_INSTANCE'],
  computed: {
    comment() {
      return this.COMMENT_INSTANCE.comment
    },
    position() {
      return this.COMMENT_INSTANCE.position
    },
    language() {
      return this.COMMENT_INSTANCE.language
    }
  },
})
</script>
