import { nextTick } from 'vue'
import { SToast } from '@shein-aidc/sui-toast/mobile'
/* eslint-disable require-atomic-updates */
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import schttp from 'public/src/services/schttp'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { addCart } from 'public/src/pages/goods_detail_v2/innerComponents/top/AddToCartBar/utils.js'
import { getQuickAddCartInstance, SCENE_MAP, plugins } from 'public/src/pages/components/product/quick_add_v3/common.js'
import { CustomizationProductPlugin } from '@shein-aidc/bs-quick-add-cart-standard-mobile'
import { matchReviewSkuCode } from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/Reviews/utils.js'

daEventCenter.addSubscriber({ modulecode: '1-6-7' })
daEventCenter.addSubscriber({ modulecode: '1-6-2' })

export default {
  computed: {
    ...mapGetters('productDetail/common', [
      'goods_id',
      'mall_code',
      'cache_sku_list',
      'is_customization',
      'compliance_mode',
      'fs_abt',
      'language_v2'
    ]),
    ...mapGetters('productDetail/Reviews', [
      'translate_language',
    ]),
    ...mapGetters('productDetail/Recommend', [
      'product_bff_depend_data',
    ]),
    ...mapState('productDetail/CommentPopup', ['curr_translate_lang', 'default_lang',]),
    ...mapGetters('productDetail/CommentPopup', ['currentFilterGoodsSn']),
  },
  data() {
    return {
      likeLoading: false,
      showReport: false,
      reportCommentId: null, // 举报
      reportSkc: null
    }
  },
  methods: {
    ...mapMutations('newProductDetail/common', ['updateIsLoginChange']),
    ...mapMutations('productDetail/CommentPopup', [
      'close_view_comment_pop',
      'change_view_comment_pop_ready',
      'update_curr_translate_lang',
      'update_translate_model',
      'update_comment_type_for_img',
      'update_curr_comment_id_info',
      'update_show_review_imgbox',
      'change_comment_data_for_like'
    ]),
    ...mapActions('productDetail/CommentPopup', ['fetch_comment_translate']),
    ...mapActions('productDetail/common', ['show_login_modal']),
    ...mapActions('productDetail/addBag', [
      'open_quick_add_main_product',
    ]),
    ...mapActions('productDetail/Reviews', [
      'get_sku_list_from_static',
    ]),
    ...mapActions('productDetail/Recommend', [
      'set_goods_detail_scroll',
    ]),
    changeHash(value) {
      location.hash = value ? '#' + value : ''
    },
    /**
     * 图片点击
     */
    imageClickHandle({ report_id, comment_id, index }, position) {
      this.updateCommentInfoHandle({ comment_id, index }, position)
      // 需要更新弹CommentPop Ready状态
      this.change_view_comment_pop_ready()
      nextTick(() => {
        this.update_show_review_imgbox(true)
      })

      if (report_id) {
        daEventCenter.triggerNotice({
          daId: position === 'inner' ? '1-6-2-28' : '1-6-7-28',
          extraData: {
            freetrial_id: report_id
          }
        })
      } else {
        // 评论详情页不再上报1-6-2-3
        if(position === 'inner' || position === 'review-gallery') return 
        daEventCenter.triggerNotice({
          daId: position === 'inner' ? '1-6-2-3' : '1-6-7-3',
          extraData: {
            review_location: position === 'inner' ? 'page_goods_reviews' : 'page_goods_detail'
          }
        })
      }
    },
    /**
     * 更新当前评论信息
     * @param {Object} commentInfo
     * @param {*} type
     */
    updateCommentInfoHandle({ comment_id, index }, type) {
      // this.update_comment_type_for_img(type === 'outer' ? 'outer' : 'inner')
      this.update_comment_type_for_img(type || 'inner')
      this.update_curr_comment_id_info({
        currClickCommentId: comment_id,
        currClickCommentIndex: index
      })
    },

    showLogin(params = {}) {
      const { cb } = params
      if (typeof window !== 'undefined' && window?.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD && window?.gbCommonInfo?.WEB_CLIENT === 'shein') {
        this.show_login_modal({
          params: {
            from: 'other',
            reload: true,
          },
          cb: async () => {
            if (cb) {
              await cb()
            }
          },
        })
        this.changeHash('login')
        return
      }
      SHEIN_LOGIN.show({
        show: true,
        from: 'other',
        reload: true,
        cb: () => {
          this.updateIsLoginChange(true)
          if (isLogin()) {
            this.$store.dispatch('newProductDetail/asyncHotModles', {
              goods_id: this.goods_id,
              mallCode: this.mall_code
            })
          }
          cb && cb()
        }
      })
    },
    /**
     * 点赞
     * @param {*} cb
     */
    async likeClickHandle({}, commentData, position) {
      if (this.likeLoading) return
      const currCommentId = commentData.comment_id
      const currStatus = commentData.current_member_zan == 1 ? 1 : 0
      let currThumbsUp = -1
      // let url = ''
      let type = !~currCommentId.toString().indexOf('rp') ? 1 : 2
      if (!isLogin()) {
        // 未登录
        if (typeof window !== 'undefined' && window?.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD && window?.gbCommonInfo?.WEB_CLIENT === 'shein') {
          this.showLogin({ 
            cb: async () => {
              (location.hash === '#login') && history.go(-1)
              await this.likeClickHandle({}, commentData, position)
            } 
          })
          return
        }
        this.showLogin()
        return
      } else {
        currThumbsUp = currStatus == 1 ? 0 : 1
      }

      this.likeLoading = true

      const useBffApi = true
      let options = {}
      let data = {}

      if(type === 1) {
        options = {
          url: '/product/like_goods_review',
          params: {
            comment_id: currCommentId,
            thumbs_up: currThumbsUp,
            sku: this.currentFilterGoodsSn
          },
          useBffApi,
          method: 'GET'
        }
      } else if (type === 2) {
        options = {
          url: '/user/trial/like_report',
          params: {
            action: currThumbsUp,
            report_id: currCommentId.replace('rp', ''),
            sku: this.currentFilterGoodsSn
          },
          useBffApi,
          method: 'POST'
        }
      }
      data = await schttp(options)
      this.likeLoading = false
      if (data.code == 0) {
        this.change_comment_data_for_like({
          comment_id: currCommentId,
          params: {
            current_member_zan: +currStatus === 1 ? 0 : 1,
            memberIsLike: +currStatus === 1 ? 0 : 1,
            like_num:
              +currStatus === 1
                ? Number(commentData.like_num < 1 ? 1 : commentData.like_num) - 1
                : Number(commentData.like_num) + 1
          }
        })

        if (currStatus == 0) {
          daEventCenter.triggerNotice({
            daId: `1-6-${position === 'inner' ? '2' : '7'}-${commentData.reportId ? '27' : '4'}`,
            extraData: {
              is_cancel: 0,
              review_location: position === 'inner' ? 'page_review' : 'page_goods_detail'
            }
          })
        } else {
          daEventCenter.triggerNotice({
            daId: `1-6-${position === 'inner' ? '2' : '7'}-${commentData.reportId ? '27' : '4'}`,
            extraData: {
              is_cancel: 1,
              review_location: position === 'inner' ? 'page_review' : 'page_goods_detail'
            }
          })
        }
      } else if (data.code == 200728 || data.code == 300206 || (useBffApi && data.code === '00101110')) {
        if (typeof window !== 'undefined' && window?.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD && window?.gbCommonInfo?.WEB_CLIENT === 'shein') {
          return this.showLogin({ 
            cb: async () => {
              (location.hash === '#login') && history.go(-1)
              await this.likeClickHandle({}, commentData, position)
            } 
          })
        }
        return this.showLogin()
      }
    },
    /**
     * 更新当前翻译语种
     * @param {*} translate_lang
     */
    updateTranslateLang(translate_lang) {
      this.update_curr_translate_lang(translate_lang)
    },
    /**
     * 更新翻译 Model
     * @param {*} params
     * @param {*} position
     */
    updateTranslateModelHandle(params, position) {
      const { comment_id, comment_lang, content, reportId, isOutStoreReview } = params
      this.update_translate_model({
        show: true,
        lang: '',
        comment_lang,
        cb: () => {
          this.fetch_comment_translate({
            content,
            comment_id,
            comment_lang,
            position,
            isOutStoreReview
          })
          daEventCenter.triggerNotice({
            daId: `1-6-${position === 'inner' ? '2' : '7'}-${reportId ? '25' : '15'}`,
            extraData: {
              review_id: comment_id,
              translate_language: this.curr_translate_lang || this.default_lang,
              type: 'single',
              activity_from: position === 'inner' ? 'page_goods_reviews' : 'page_goods_detail'
            }
          })
        }
      })

      const daId = reportId ? `1-6-${position === 'inner' ? '2' : '7'}-23` : `1-6-${position === 'inner' ? '2' : '7'}-13`
      const extraData = {
        review_id: comment_id,
        translate_language: this.curr_translate_lang || this.default_lang,
        activity_from: position === 'inner' ? 'page_goods_reviews' : 'page_goods_detail'
      }
      if (!reportId) {
        extraData.OutReview = isOutStoreReview ? 1 : 0
        extraData.type = 'single'
      }
      daEventCenter.triggerNotice({ daId, extraData })
    },
    /**
     *
     * @param {Object} params
     * @param {String} position
     */
    fetchCommentTranslateEvent(params, position) {
      const { content, comment_id, comment_lang, reportId, curr_lang, isOutStoreReview } = params
      this.fetch_comment_translate({
        content,
        comment_id,
        comment_lang,
        position,
        curr_lang,
        isOutStoreReview
      })
      const positionIndex = position === 'inner' ? '2' : '7'
      const daId = reportId ? `1-6-${positionIndex}-23` : `1-6-${positionIndex}-13`
      const extraData = {
        review_id: comment_id,
        translate_language: this.curr_translate_lang || this.default_lang,
        activity_from: this.position === 'inner' ? 'page_goods_reviews' : 'page_goods_detail'
      }
      if (!reportId) {
        extraData.OutReview = isOutStoreReview ? 1 : 0
        extraData.type = 'single'
      }
      daEventCenter.triggerNotice({ daId, extraData })
    },
    /**
     * 点击翻译
     * @param {Object} params
     * @param {String} position
     */
    clickTranslateHandle(params, position) {
      // 需要更新弹CommentPop Ready状态
      this.change_view_comment_pop_ready()
      let curr_translate_lang
      const { report_id, comment_id, comment_lang, content, comment_el, isOutStoreReview } = params
      window.$commentel = comment_el

      if (!this.curr_translate_lang) {
        const translate_lang = localStorage.getItem('translate_lang') || ''
        if (translate_lang) {
          this.updateTranslateLang(translate_lang)
        }
      }

      if (!this.translate_language.map(item => item.translate_language)?.includes(this.curr_translate_lang)) {
        if (this.translate_language.length > 1) {
          this.updateTranslateModelHandle(
            {
              comment_id,
              comment_lang,
              content,
              reportId: report_id,
              isOutStoreReview
            },
            position
          )
          return
        } else {
          curr_translate_lang = this.translate_language[0]?.translate_language
        }
      }

      this.fetchCommentTranslateEvent(
        {
          content,
          comment_id,
          comment_lang,
          reportId: report_id,
          curr_lang: curr_translate_lang,
          isOutStoreReview
        },
        position
      )
    },
    closeTranslateHandle(params, position) {
      const reportId  = params || ''
      daEventCenter.triggerNotice({
        daId: `1-6-${position === 'inner' ? '2' : '7'}-${reportId ? '26' : '12'}`
      })
    },
    changeTranslateLanguage(params, position) {
      const {
        translate_lang,
        content,
        comment_lang,
        report_id,
        comment_id,
        cb,
        isOutStoreReview
      } = params
      if (!report_id) {
        daEventCenter.triggerNotice({
          daId: `1-6-${position === 'inner' ? '2' : '7'}-11`,
          extraData: {
            review_location: position === 'inner' ? 'page_goods_reviews' : 'page_goods_detail',
            OutReview: isOutStoreReview ? 1 : 0
          }
        })
      }
      report_id &&
        daEventCenter.triggerNotice({
          daId: `1-6-${position === 'inner' ? '2' : '7'}-24`
        })
      this.update_translate_model({
        show: true,
        lang: translate_lang,
        comment_lang,
        cb: () => {
          this.fetch_comment_translate({
            content,
            comment_id,
            comment_lang,
            position,
            isOutStoreReview,
            cb
          })
          daEventCenter.triggerNotice({
            daId: `1-6-${position === 'inner' ? '2' : '7'}-${report_id ? '25' : '15'}`,
            extraData: {
              review_id: comment_id,
              translate_language: this.curr_translate_lang || this.default_lang,
              type: 'single',
              activity_from: position === 'inner' ? 'page_goods_reviews' : 'page_goods_detail'
            }
          })
        }
      })
    },
    /**
     * revert
     * @param {*} param
     */
    showOriginComment({ showOrigin, review_id }, position) {
      daEventCenter.triggerNotice({
        daId: `1-6-${position === 'inner' ? '2' : '7'}-40`,
        extraData: {
          showOrigin,
          review_id
        }
      })
    },
    /**
     * 内容展开
     * @param {*} status
     */
    contentShowAllHandle({ status }, position) {
      status &&
        daEventCenter.triggerNotice({
          daId: `1-6-${position === 'inner' ? '2' : '7'}-34`
        })
    },
    /**
     * 会员尺码
     * @param {String} status
     */
    sizeShowAllHandle({ review_id }, position) {
      if(position === 'inner') {
        daEventCenter.triggerNotice({
          daId: '1-6-2-92',
          extraData: {
            type: review_id
          }
        })
        return
      }
      daEventCenter.triggerNotice({
        daId: '1-6-2-33'
      })
    },

    async addCart(params, position) {
      this.addCartSa(params, position)
      const mall_code = this.mall_code
      let goods_id  = params?.goods_id || this.goods_id // 试用报告无goods_id
      let skuList = this.cache_sku_list
      if(goods_id !== this.goods_id) { // 非主商品需请求静态接口了获取
        skuList = await this.get_sku_list_from_static(params)
        // 当前goods_id无skuList时，使用主商品goods_id
        if(!skuList.length) {
          goods_id = this.goods_id
        }
      }
      const sku_code = skuList[0]?.sku_code || ''
      // 根据实验命中，是否直接选择sku
      let matchSkuCode = ''
      const review_sku_list = params?.sku_info_list || []
      const default_select_sku = this.review_goods_info_abt?.default_select_sku // 评论楼层和评论弹窗有这个值
      if(default_select_sku) {
        matchSkuCode = matchReviewSkuCode(review_sku_list, skuList)
      }
      const need_into_cart = (!matchSkuCode || !sku_code) && default_select_sku // 满足实验 单sku或者多sku 无sku_code
      // feat: 无goods_id(试用报告)也直接唤起加车
      if(!params?.goods_id || !skuList.length || skuList?.length > 1 || this.is_customization || need_into_cart) {
        let extraConfigParams = {}
        if(matchSkuCode) {
          extraConfigParams = { // 打开快车自动选中sku配置
            salesAttrConfig: {
              selectedAttrSkuCode: matchSkuCode
            }
          }
        }
        this.openQuickAddCart({ ...params, goods_id, extraConfigParams }, position)
        setTimeout(() => {
          if(!params?.goods_id || !skuList.length || need_into_cart) { // 增加sku_code匹配不到的提示
            SToast(this.language_v2.SHEIN_KEY_PWA_35779 || `Item mentioned in the review can' t be found, you can add the current item.`, 2000)
          }
        }, 1000)
        return
      }

      const reqParams = {
        goods_id,
        quantity: 1,
        mall_code: mall_code,
        promotion_id: '0',
        promotion_type: '0',
        sku_code,
      }
      const cartData = await addCart(reqParams, { 
        language: this.language_v2,
        // extraParams: {
        //   page_name: position === 'inner' ? 'page_goods_reviews' : '',
        // },
        extraSaParams: {
          location: `page_review_${params.reportId || params.comment_id}`,
        },
        // target,
        // extraParams,
        // extraSaParams,
        // base_info,
        // store_info,
        // errorInfo,
        // closeSuccessPop: true,
        // isFromBigImage,
      })
      const isSuccess = cartData?.result?.code == 0
      if(isSuccess) {
        // 下滑ymal
        if(position === 'outer') {
          this.set_goods_detail_scroll()
        } else if(position === 'inner') {
          this.update_need_scroll_review_ymal(true)
        }
        // 更新购物车数量
        this.$store.dispatch('fetchCartInfo', null, { root: true })
        setTimeout(() => {
          window?._gb_cart_tag_tips_?.update({
            excludeScroll: true
          })
          window.vBus && window.vBus.emit('triggerAddCompletedFloatCart', { animation: false })
        }, 2000)
      }
    },
    openQuickAddCart(params, position) {
      const mallCode = this.mall_code
      const goods_id  = params?.goods_id || this.goods_id
      const locationPre = position === 'inner' ? 'popup_review' : 'page_review'
      const pluginArr = [CustomizationProductPlugin(true)]
      // 顺手购
      const buyInHandAbt = this.fs_abt?.popupbuyinhand?.param?.popupbuyinhand
      // todo 0401先屏蔽buynow
      if((buyInHandAbt === 'showall' || buyInHandAbt === 'addnow')) {
        pluginArr.push(plugins.buyInHandPlugin({
          complianceMode: this.compliance_mode,
          language: this.language_v2,
          product_bff_depend_data: this.product_bff_depend_data,
        }))
      }
      const extraConfigParams = params.extraConfigParams || {}
      // 打开加车
      getQuickAddCartInstance(SCENE_MAP.DETAILS).open(
        { goods_id, mallCode },
        {
          plugins: pluginArr,
          excludePlugins: ['syncDetailsData', 'BuyNow', 'Gifting', 'OneClickPay'],
          featureConfig: {
            // 展示凑单到手价等
            showNoSatisfied: true,
            showEstimatedPrice: true,
            showNEstimatedPrice: true,
            showAbPrice: true,
            mainPageGoodsId: this.goods_id
          },
          analysisConfig: {
            from: 'main',
            review_location: 'page_review',
            postData: {
              location: `${locationPre}_${params.reportId || params.comment_id}`,
            }
          },
          callbacks: {
            onJumpDetailClick: () => {
              if(position === 'inner' && goods_id === this.goods_id) {
                this.close_view_comment_pop()
                history.go(-1)
              }
            },
            onAddCartFinished: (data) => {
              if (data?.isSuccess) {
                // 下滑ymal
                if(position === 'outer') {
                  this.set_goods_detail_scroll()
                } else if(position === 'inner') {
                  this.update_need_scroll_review_ymal(true)
                }
                setTimeout(() => {
                  window.vBus && window.vBus.emit('triggerAddCompletedFloatCart', { animation: false })
                }, 2000)
              }
            },
          },
          ...extraConfigParams
        },

      )


      daEventCenter.triggerNotice({
        daId: '1-6-1-91',
        extraData: {
          goods_id,
          activity_from: 'main',
          button_type: 'add_to_bag',
          review_location: 'page_reviews_image',
          page_name: position === 'inner' ? 'page_goods_reviews' : 'page_goods_detail',
          location: `page_review_${params.reportId || params.comment_id}`,
        }
      })
    },
    addCartSa(params, position) {
      const { commentHotOrSoldOutLabelType, member_size_new, comment_id } = params || {}
      const hasAlmostsoldout = commentHotOrSoldOutLabelType == 1
      const hasHotsell = commentHotOrSoldOutLabelType == 2
      const trueToSizeItem = member_size_new?.find?.(item => item.rule_name_en === 'member_overall_fit') || {}
      const commentIdStr = String(comment_id || '')
      const isReport = commentIdStr.indexOf('rp') > -1
      const is_truetosize = isReport ? trueToSizeItem?.member_overall_fit_type || '' : trueToSizeItem?.member_overall_fit || ''
      daEventCenter.triggerNotice({
        daId: '1-6-2-106',
        extraData: {
          page_name: position === 'inner' ? 'page_goods_reviews' : 'page_goods_detail',
          is_almostsoldout: +!!hasAlmostsoldout, //是否有低库存，1=有，0=无
          is_hotsell: +!!hasHotsell, //是否有是否有热销，1=有，0=无
          is_truetosize: is_truetosize,
        }
      })
    }
  }
}
